import ActionTypes from '../constants';


interface IInitialProps{
    currentFrame: number;
    frameCount: number;
    frameDelay: number;
    delayDelta: number;
    isPlaying: boolean;
    width: number,
    height: number,
}

const initialState:IInitialProps = {
    currentFrame: 0,
    frameCount: 1,
    frameDelay: 120,
    delayDelta: 1,
    isPlaying: false,
    width: 0,
    height: 0,
}

export default (state = initialState, action:{type: string, payload: any}) => {
    switch(action.type){
        case ActionTypes.setPlaying:
        return {
            ...state,
            isPlaying: action.payload.isPlaying,
        }
        case ActionTypes.setDimensions:
        return {
            ...state,
            width: action.payload.dimensions.width,
            height: action.payload.dimensions.height,
        }
        case ActionTypes.setFrameCount:
            return {
                ...state,
                frameCount: action.payload.frameCount,
            }
        case ActionTypes.setFrameDelay:
            return {
                ...state,
                frameDelay: action.payload.frameDelay,
            }
        case ActionTypes.setFrame:
            return {
                ...state,
                currentFrame: action.payload.frame,
            }
        case ActionTypes.setDelayDelta:
            return {
                ...state,
                delayDelta: action.payload.delayDelta,
            }
        default:
            return {
                ...state
            }
    }
}