import ActionTypes from '../constants';


export default (state = {frames:[]}, action:{type: string, payload: any}) => {
    switch(action.type){
        case ActionTypes.setFrames:
        return {
            ...state,
            frames: action.payload.frames,
        }
        default:
            return {
                ...state
            }
    }
}