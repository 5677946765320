import React from 'react';
import withStyles from 'react-jss';

const clrGray = '#b2b2b2';
const clrLightGray = '#D8D8D8';
const clrDarkGray = '#545454';

const styles = {
    editorSegment: {
        marginTop: 25,
    },
    textColorHeaderContainer: {
        display: 'flex',
        flewWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textColorHeaderImg: {
        fontSize: 20,
        paddingTop: 6,
        marginRight: 10,
    },
    textColorHeaderText: {
        color: clrLightGray,
        fontFamily: 'Helvetica',
        fontWeight: 600,
        borderBottom: `1px solid ${clrDarkGray}`,
        padding: '7px 0',
        flex: 1,
    }
}

interface IProps {
    sheet?: any;
    title: string;
    icon: string;
    children?: any;
    classes?: any;
}

const EditorSegment = (props: IProps) => {
    const { classes } = props;
    const { title, icon } = props;
    return (
        <div className={classes.editorSegment}>
            <div className={classes.textColorHeaderContainer}>
                <div className={classes.textColorHeaderImg}>
                    <span style={{ color: clrGray }} className={icon}></span>
                </div>
                <span className={classes.textColorHeaderText}>{title}</span>
            </div>
            {
                props.children
            }
        </div>
    )
}

export default withStyles(styles)(EditorSegment)