import React, { Component } from 'react';
import withStyles from 'react-jss';
import { connect } from 'react-redux';
import { setFrame, setPlaying } from '../../redux/actions/timeline';
import _ from 'lodash';

const styles = {
    container: {
        display: 'flex',
        height: '100%',
        padding: '10px 0',
        borderTop: '1px solid #414141',
        borderBottom: '1px solid #414141',
        backgroundColor: '#1c1c1c',
    },
    timeBar: {
        height: '100%',
        borderLeft: '1px solid #414141',
        flexShrink: 0,
        position: 'relative',
        "user-select": 'none',
        "&.active:before": {
            content: "''",
            width: 1,
            height: 'calc(30vh - 64px)',
            backgroundColor: '#fc3d4a',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            margin: 'auto',
        },
        "&.active:after": {
            content: "''",
            width: 5,
            height: 5,
            backgroundColor: '#fc3d4a',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: -10,
            zIndex: 2,
            margin: 'auto',
            borderRadius: '50%',
            boxSizing: 'border-box',
            cursor: 'pointer',
            transform: 'scale(3)',
            boxShadow: 'inset 0 0 0 0.5px #fff',
            transformOrigin: 'top',
        }
    }
}

interface IProps {
    classes?: any;
    frameCount: number;
    setFrame: Function;
    timelineReducer: any;
    setPlaying: Function;
}

const minBarWidth = 5;

class TimelineControl extends Component<IProps>{
    public state: any;
    public slider: any;
    constructor(props: IProps) {
        super(props)
        this.state = {
            barWidth: minBarWidth,
            activeBar: 0,
        }
        this.setActive = this.setActive.bind(this);
        this.moveTimeline = this.moveTimeline.bind(this);
        this.handleBarClick = this.handleBarClick.bind(this);
    }
    componentWillMount() {
        const barWidth = (window.innerWidth - 320) / (this.props.frameCount || 100) > minBarWidth ? (window.innerWidth - 320) / (this.props.frameCount || 100) : minBarWidth;
        this.setState({
            barWidth,
        })
    }
    componentWillReceiveProps(newProps: IProps) {
        const barWidth = (window.innerWidth - 320) / (newProps.frameCount || 100) > minBarWidth ? (window.innerWidth - 320) / (newProps.frameCount || 100) : minBarWidth;
        const activeBar = newProps.timelineReducer.currentFrame;
        this.setState({
            barWidth,
            activeBar,
        })
    }

    setActive(bool: boolean) {
        window.addEventListener('mousemove', this.moveTimeline);
        window.addEventListener('mouseup', this.removeMouseUpEvent);
    }

    setActiveBar(index: number) {
        this.setState({
            activeBar: index,
        })
        this.props.setFrame(index);
    }

    handleBarClick(index: number) {
        if (this.props.timelineReducer.isPlaying) {
            this.props.setPlaying(false);
        }
        this.setActiveBar(index);
    }

    moveTimeline(event: MouseEvent) {
        if (this.props.timelineReducer.isPlaying) {
            this.props.setPlaying(false);
        }
        var childArray = Array.prototype.slice.call(this.slider.children)
        childArray.map((el, i) => {
            const { left } = el.getBoundingClientRect()
            if (event.clientX >= left && event.clientX <= left + el.offsetWidth) {
                this.setActiveBar(i);
            }
        })
    }

    removeMouseUpEvent = () => {
        window.removeEventListener('mousemove', this.moveTimeline);
        window.removeEventListener('mouseup', this.removeMouseUpEvent);
    }

    render() {
        const { classes, frameCount } = this.props;
        const { barWidth, activeBar } = this.state;
        return (
            <div className={classes.container} ref={(ref) => { this.slider = ref }}>
                {
                    _.range(frameCount).map((el, i) => {
                        const className = el === activeBar ? classes.timeBar + ' active' : classes.timeBar;
                        return (
                            <div
                                key={i}
                                onClick={() => this.handleBarClick(i)}
                                onMouseDown={() => this.setActive(true)}
                                className={className}
                                style={{ width: barWidth }} />
                        )
                    })
                }
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    ...state
})

export default connect(mapStateToProps, { setFrame, setPlaying })(withStyles(styles)(TimelineControl));