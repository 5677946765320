import React, { Component } from 'react';
import {connect} from 'react-redux';
import withStyles from 'react-jss';
import PlayIcon from '../icons/IconPlay';
import StopIcon from '../icons/IconStop';
import PauseIcon from '../icons/IconPause';
import FrameIcon from '../icons/IconFilm';

import { setFrame,setPlaying } from '../../redux/actions/timeline';
import timelineReducer from '../../redux/reducers/timelineReducer';

const styles = {
    root:{
        background: '#1c1c1c',
        width: 250,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 50,
        position: 'absolute',
        bottom: 20,
        borderRadius: 60,
        fontSize: 20,
        padding: '0 35px',
    },
    controlButtonContainer:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 60,
    },
    frameCount:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 20,
    },
    icon:{
        height: 24,
    },
    controlIcon:{
        height: 24,
        cursor: 'pointer',
        transition: 'all 0.15s ease-in-out',
        "& > span":{
            transition: 'all 0.15s ease-in-out',
        },
        "&:hover":{
            transform: 'scale(1.2)',
            "& > span":{
                color: '#fc3d4a !important',
            }
        }
    },
    framesText:{
        fontFamily: 'Helvetica',
        fontSize: 17,
        color: '#b2b2b2',
        marginLeft: 7,
    }
}

interface IProps{
    classes?: any;
    timelineReducer: any;
    setFrame: Function;
    setPlaying: Function;
}

class Controls extends Component<IProps> {
    public state: any;
    private playInterval : any;
    constructor(props: IProps) {
        super(props);
        this.state = {
            currentFrame: 0,
            frameCount: 1,
            isPlaying: false,
            frameDelay: 0,
            delayDelta: 1,
        }
        this.handlePlay = this.handlePlay.bind(this);
        this.handleStop = this.handleStop.bind(this);
        this.playInterval = null;
    }

    componentDidMount(){
        const { currentFrame, frameCount, frameDelay, delayDelta, isPlaying } = this.props.timelineReducer;
        this.setState({
            currentFrame,
            frameCount,
            frameDelay,
            delayDelta,
            isPlaying,
        })
    }

    handlePlay = () => {
        const { frameDelay, delayDelta } =  this.state;
        clearInterval(this.playInterval);
        this.props.setPlaying(true);
        this.playInterval = setInterval(()=>{
            const { frameCount, currentFrame } =  this.state;
            if(currentFrame < frameCount-1){
                const frameToSet = currentFrame+1;
                this.setState({
                    currentFrame: frameToSet,
                },()=>{
                    this.props.setFrame(frameToSet);
                })
            } else {
                this.setState({
                    currentFrame: 0,
                },()=>{
                    this.props.setFrame(0);
                })
            }
        },frameDelay/delayDelta)
    }

    handleStop = () => {
        clearInterval(this.playInterval);
        this.props.setFrame(0);
        this.props.setPlaying(false);
    }

    handlePause = () => {
        clearInterval(this.playInterval);
        this.props.setPlaying(false);
    }

    componentWillReceiveProps(newProps:IProps){
        const { currentFrame, frameCount, frameDelay, delayDelta, isPlaying } = newProps.timelineReducer;
        const shouldUpdateDelay = delayDelta != this.props.timelineReducer.delayDelta;
        const shouldPause = isPlaying != this.props.timelineReducer.isPlaying;
        this.setState({
            currentFrame,
            frameCount,
            frameDelay,
            delayDelta,
            isPlaying,
        },()=>{
            if(shouldUpdateDelay && this.state.isPlaying){
                this.handlePause();
                this.handlePlay();
            }
            else if(shouldPause && !this.state.isPlaying){
                this.handlePause();
            }
        })
    }

    render() {
        const reduxTimelineState = this.props.timelineReducer;
        const { classes } = this.props;
        const { frameCount,currentFrame,isPlaying } =  this.state;
        return (
            <div className={classes.root}>
            <div className={classes.controlButtonContainer}>
                {
                    isPlaying
                    ?
                    <span><PauseIcon onClick={this.handlePause} color="#b2b2b2" className={classes.controlIcon}/></span>
                    :
                    <span><PlayIcon onClick={this.handlePlay} color="#b2b2b2" className={classes.controlIcon}/></span>
                }
                <span><StopIcon onClick={this.handleStop} color="#b2b2b2" className={classes.controlIcon}/></span>
            </div>
                <div className={classes.frameCount}>
                    <span><FrameIcon color="#b2b2b2" className={classes.icon}/></span>
                    <span className={classes.framesText}>{currentFrame+1 || 1} / {frameCount}</span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        ...state,
    }
}

export default connect(mapStateToProps,{setFrame, setPlaying})(withStyles(styles)(Controls));
