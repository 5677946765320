import React,{Component, ChangeEvent} from 'react';
import withStyles from 'react-jss';
import {connect} from 'react-redux';
import SnailIcon from '../icons/IconSnail';
import { setDelayDelta } from '../../redux/actions/timeline';

const styles={
    root:{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: '0 35px'
    },
    speedText:{
        display: 'inline-block',
        color: '#b2b2b2',
        fontFamily: 'Helvetica',
        fontWeight: 'bold',
        fontSize: 14,
        marginLeft: 10,
    },
    slider:{
        marginLeft: 20,
        WebkitAppearance: 'none',
        width: '100%',
        height: 4,
        background: '#d3d3d3',
        outline: 'none',
        borderRadius: 4,
        WebkitTransition: '.2s',
        transition: 'opacity .2s',
        "&::-webkit-slider-thumb":{
            WebkitAppearance: 'none',
            appearance: 'none',
            width: 15,
            height: 15,
            background: '#b2b2b2',
            borderRadius: 15,
            cursor: 'pointer',
        },
        "&::-moz-range-thumb":{
            appearance: 'none',
            width: 15,
            height: 15,
            background: '#b2b2b2',
            borderRadius: 15,
            cursor: 'pointer',
        },
    }
}

interface IProps{
    classes?: any;
    setDelayDelta: Function;
}

class SpeedControl extends Component<IProps>{

    constructor(props:IProps){
        super(props)
        this.handleSpeedChange = this.handleSpeedChange.bind(this);
    }

    handleSpeedChange(e:any){
        const delayDelta = e.target.value <= 0.25 ? 0.25 : e.target.value;
        this.props.setDelayDelta(delayDelta);
    }

    render(){
        const {classes} = this.props;
        return(
            <div className={classes.root}>
                <SnailIcon color="#b2b2b2"/>
                <span className={classes.speedText}>სიჩქარე</span>
                <input onChange={this.handleSpeedChange} className={classes.slider} type="range" min="0" max="2" defaultValue="1" step="0.25"/>
            </div>
        )
    }
}

export default connect(null, { setDelayDelta })(withStyles(styles)(SpeedControl));