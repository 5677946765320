import React, { Component } from 'react'

export default class IconCheck extends Component<any> {
    render() {
        return (
            <div className={this.props.className}>
                <span style={{ color: (this.props.color || '#000') }} className="icon-check"></span>
            </div>
        )
    }
}