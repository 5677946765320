import React, { Component } from 'react';
import withStyles from 'react-jss';
import StudioLogo from '../../assets/images/studio-logo.svg'
import UploadSelector from './components/UploadSelector';
import Layout from './layout/Layout';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { connect } from 'react-redux';
import { signIn, getProfile } from '../../redux/actions/auth';
import Cookies from '../../helpers/cookies';
import Button from '../../components/Button';

const styles = {
    studioLogo:{
        height: 40,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(calc(-50% - 165px))',
        "&.animate":{
            animation: 'animateLogo 0.5s ease-out forwards',
        },
    },
    authButton:{
        fontSize: 15,
    },
    userContainer:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        "&.animate":{
            animation: 'animateAvatar 0.5s ease-out forwards',
        },
        "& img":{
            objectFit: 'cover',
            width: 68,
            height: 68,
            borderRadius: '50%',
        },
        '& span':{
            fontFamily: 'HelveticaCaps',
            fontWeight: 'bold',
            color: '#D8D8D8',
            paddingLeft: 20,
        }
    },
    copyright:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "& span":{
            color: '#808080',
            fontFamily: 'Helvetica',
        }
    },
    "@keyframes animateAvatar":{
        "from":{
            position: 'relative',
            top: '0',
            opacity: 1,
        },
        "to":{
            position: 'relative',
            top: '-20%',
            opacity: 0,
        }
    },
    "@keyframes animateLogo":{
        "from":{
            top: '50%',
            height: 40,
        },
        "to":{
            top: '20px',
            transformOrigin: 'top',
            transform: 'scale(0.5)',
        }
    },
}

interface IProps{
    classes: any;
    signIn?: any;
    getProfile?: any;
    auth?: any;
}

class Home extends Component<IProps>{
    public state: any;
    constructor(props:IProps){
        super(props)
        this.onSelected = this.onSelected.bind(this);
        this.onFbLogin = this.onFbLogin.bind(this);
        this.state={
            uploadActive: false,
            isLoggedIn: false,
            loadingState: true,
        }
    }

    componentWillMount(){
        const accessToken = Cookies.get('giffy.accessToken')
        if(accessToken){
            this.props.getProfile(accessToken);
            this.setState({
                isLoggedIn: true,
            })
        } else {
            this.setState({
                loadingState: false,
            })
        }
    }

    onSelected(el:string){
        this.setState({
            uploadActive: true,
        })
    }

    onFbLogin(data:any){
        this.props.signIn(data.accessToken, data.id);
    }

    componentDidUpdate(prevProps: IProps){
        if(this.props.auth.accessToken != prevProps.auth.accessToken){
            this.props.getProfile(this.props.auth.accessToken);
            Cookies.set('giffy.accessToken', this.props.auth.accessToken, 1)
            this.setState({
                isLoggedIn: true,
            })
        }
        console.log(this.props.auth.id,prevProps.auth.id)
        if(this.props.auth.id != prevProps.auth.id){
            this.setState({
                loadingState: false,
            })
        }
    }

    render(){
        const { classes, auth } = this.props;
        const { uploadActive, isLoggedIn, loadingState } = this.state;
        const animClass = uploadActive ? "animate" : '';
        return(
            <Layout>
                <React.Fragment>
                    {
                            !loadingState
                            ?
                            <React.Fragment>
                                {
                                    isLoggedIn
                                    ?
                                    <div className={[classes.userContainer, animClass].join(' ')}>
                                        <img  src={auth.profilePic}/>
                                        <span>{auth.username}</span>                   
                                    </div>
                                    :
                                    <FacebookLogin
                                        appId="242786766150327"
                                        fields="name,email,picture"
                                        callback={this.onFbLogin}
                                        render={(renderProps:any) => (
                                            <Button style="fb" className={classes.authButton} onClick={renderProps.onClick}>ავტორიცაზია</Button>
                                          )}
                                        />                            
                                }
                            </React.Fragment>  
                            :
                            <div className={[classes.userContainer, animClass].join(' ')} />
                    }           
                    <img className={[classes.studioLogo, animClass].join(' ')} src={StudioLogo}/>
                    <UploadSelector onSelected={this.onSelected}/>
                </React.Fragment>
            </Layout>
        )
    }
}

const mapStateToProps = (state:any) => {
    return{
        auth: state.authReducer,
    }
}

export default connect(mapStateToProps, {signIn, getProfile} )(withStyles(styles)(Home));