import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './pages/home/index';
import Upload from './pages/home/upload';
import Link from './pages/home/link';
import Youtube from './pages/home/youtube';
import ReadyGif from './pages/home/readygif';
import EditCrop from './pages/home/editcrop';
import Loader from './pages/home/loader';
import Editor from './pages/editor';
import './App.css';
import './fonts/fonts.css';


class App extends Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route exact path='/home/upload' component={Upload}/>
          <Route exact path='/home/loader' component={Loader}/>
          <Route exact path='/home/link' component={Link}/>
          <Route exact path='/home/youtube' component={Youtube}/>
          <Route exact path='/home/readygif' component={ReadyGif}/>
          <Route exact path='/home/editcrop/:id' component={EditCrop}/>
          <Route path='/editor' component={Editor}/>
        </Switch>
      </div>
    );
  }
}

export default App;
