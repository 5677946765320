import React, { Component } from 'react';
import 'rc-slider/assets/index.css';
import './styles/slider.css';
import Slider,{Range} from 'rc-slider';
import withStyles from 'react-jss';
import Layout from './layout/Layout';
import YoutubeIcon from '../../assets/icons/youtube.svg';
import {Redirect} from 'react-router-dom';
import Button from '../../components/Button';
import _ from 'lodash';
import { STUDIO_API_URL } from '../../config';
import studioAPI from '../../helpers/studioAPI';

const styles = {
    root:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    studioLogo: {
        position: 'absolute',
        top: 20,
        height: 20,
    },
    youtubeContainer: {
        width: '80vmin',
        height: '80vmin',
        position: 'absolute',
        transition: 'all 0.3s',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        margin: 0,
        backgroundColor: '#292929',
        borderRadius: 32,
        boxShadow: '0 15px 30px rgba(0,0,0,30%)',
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        display: 'flex',
        padding: 20,
        "& > *":{
            animation: 'fade 0.5s ease-in-out forwards'
        },
        "&:after":{
            "width": "calc(100% - 100px)",
            "height": "5px",
            "content": "''",
            "position": "absolute",
            "bottom": "10px",
            "boxShadow": "0 -8px 15px 8px #292929"
        }
    },
    youtubeHeader:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        "& img":{
            width: 20,
            height: 20,
        },
        "& span":{
            fontFamily: 'HelveticaCaps',
            fontWeight: 'bold',
            color: '#D8D8D8',
            paddingLeft: 10,
        }
    },
    youtubeVideoContainer:{
        width: '90%',
        height: '42vmin',
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 16,
        overflow: 'hidden',
    },
    startTimeSelector:{
        width: '90%',
        marginTop: 10,
    },
    labelTop:{
        fontFamily: 'HelveticaCaps',
        fontSize: 18,
        color: '#D8D8D8',
    },
    "@keyframes fade":{
        "from":{
            opacity: 0,
        },
        "to":{
            opacity: 1,
        }
    },
}

interface IProps{
    classes: any;
}

interface YoutubeVideo{
    id: string;
    thumbnail: string,
    title: string;
}

declare global {
    interface Window {
        onYouTubeIframeAPIReady: any;
        YT: any;
    }
}

let loadYT:any;

const toHHMMSS = function (str:any) {
    str = str+'';
    var sec_num = parseInt(str, 10); // don't forget the second param
    var hours:any   = Math.floor(sec_num / 3600);
    var minutes:any = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds:any = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}

class EditCrop extends Component<any>{
    public state: any;
    public player: any;
    public youtubePlayerAnchor: any;
    public waitTimeout: any;
    public playerInterval: any;

    constructor(props:IProps){
        super(props)
        this.state = {
            handleNext: false,
            videoLength: 0,
            start: 0,
            duration: 3,
            lastSeek: 0,
            isReady: false,
            gifFile: undefined,
        }
        this.handleNext = this.handleNext.bind(this);
        this.handleMainSlider = this.handleMainSlider.bind(this);
        this.handleDurationSlider = this.handleDurationSlider.bind(this);
        this.setPlayInterval = this.setPlayInterval.bind(this);
        this.handleGenerate = this.handleGenerate.bind(this);
        this.waitTimeout = undefined;
        this.playerInterval = undefined;
    }


    handleNext(){
        const {isValidInput, inputVal} = this.state;
        if(isValidInput && inputVal){
            this.setState({
                handleNext: true,
            })
        }
    }

    componentDidMount () {
        let _self = this;
        console.log(this.props)
        if (!loadYT) {
          loadYT = new Promise((resolve) => {
            const tag = document.createElement('script')
            tag.src = 'https://www.youtube.com/iframe_api'
            const firstScriptTag = document.getElementsByTagName('script')[0]
            firstScriptTag!.parentNode!.insertBefore(tag, firstScriptTag)
            window.onYouTubeIframeAPIReady = () => resolve(window.YT)
          })
        }
        loadYT.then((YT:any) => {
          this.player = new YT.Player(this.youtubePlayerAnchor, {
            height: this.props.height || '400%',
            width: this.props.width || '100%',
            videoId: this.props.match.params.id,
            rel: 0,
            showinfo: 0,
            ecver: 2,
            mute: 1,
            events: {
              onReady: (event:any) => {
                _self.setState({
                    videoLength: this.player.getDuration(),
                    isReady: true,
                },()=>{
                    event.target.playVideo();
                })
                _self.setPlayInterval();
              }
            }
          })
        })
    }
    
    debouncedSeek = _.debounce((e:any)=>{
        this.player.seekTo(e);
        this.setState({
            start: e,
        },()=>{
            this.setPlayInterval();
        })
    },100,{ 'maxWait': 100 })

    handleMainSlider(e:any){
        const {videoLength} = this.state;
        this.debouncedSeek(videoLength*e/100);
        clearInterval(this.playerInterval);
    }

    setPlayInterval(){
        clearInterval(this.playerInterval);
        const { start, duration } = this.state;
        this.playerInterval = setInterval(()=>{
            const currentTime = this.player.getCurrentTime();
            if( currentTime >= (start + duration) ){
                this.player.seekTo(start);
            }
        },5)
    }

    handleDurationSlider(e:any){
        this.player.pauseVideo();
        clearTimeout(this.waitTimeout);
        clearInterval(this.playerInterval);
        const {start} = this.state;
        this.setState({
            duration: e/8,
        })
        this.waitTimeout = setTimeout(()=>{
            this.player.seekTo(start);
            this.player.playVideo();
            clearTimeout(this.waitTimeout);
            this.setPlayInterval()
        },1500)
        this.player.seekTo(start+e/8)
    }

    async handleGenerate(){
        const ytGifCall = await studioAPI.post('gifs/youtube',{
            id: this.props.match.params.id,
            start: this.state.start.toFixed(2),
            end: (this.state.start + this.state.duration).toFixed(2),
        },{ headers: { "Access-Control-Allow-Origin": "*", } })
        const ytGifResp = await ytGifCall.data;
        const ytGifFile = ytGifResp.gifUrl;
        const gifUrl = `${STUDIO_API_URL}${ytGifFile}`;
        this.setState({
            gifFile: gifUrl,
        },()=>{
            clearTimeout(this.waitTimeout);
            clearInterval(this.playerInterval);
            this.player.stopVideo();
            this.player.destroy();
            this.player = null;
        })
    }

    render(){
        const {classes} = this.props;
        const { start, duration, isReady,gifFile } = this.state;
        return(
            <Layout withLogo>
                {
                    gifFile&&<Redirect to={{
                        pathname: '/home/loader',
                        state: { gifUrl: gifFile }
                    }} push />
                }
                <div className={classes.root}>
                    <div className={classes.youtubeContainer}>
                        <div className={classes.youtubeHeader}>
                            <img src={YoutubeIcon}/>
                            <span>მონიშნე მონაკვეთი</span>
                        </div>
                        <div className={classes.youtubeVideoContainer}>
                            <div ref={(r) => { this.youtubePlayerAnchor = r }}></div>
                        </div>
                        {
                            isReady &&
                            <React.Fragment>
                                <div className={classes.startTimeSelector}>
                                    <p className={classes.labelTop}>საწყისი დრო <span style={{paddingLeft:20, color: '#FC3D4A'}}>{toHHMMSS(start)}</span></p>
                                    <Slider onChange={this.handleMainSlider} defaultValue={0} min={0} max={100} step={0.01}/>
                                    <p className={classes.labelTop} style={{marginTop:30}}>ხანგრძლივობა <span style={{paddingLeft:20, color: '#FC3D4A'}}>{`${duration.toFixed(2)} წამი`}</span></p>
                                    <Slider onChange={this.handleDurationSlider} min={0} max={80} defaultValue={24} />
                                </div>
                                <div style={{marginTop: 50}}>
                                    <Button onClick={this.handleGenerate}>გაგიფე</Button>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </Layout>
        )
    }
}

export default withStyles(styles)(EditCrop)