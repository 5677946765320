import React, { Component } from 'react';
import withStyles from 'react-jss';
import Layout from './layout/Layout';
import YoutubeIcon from '../../assets/icons/youtube.svg';
import {Redirect} from 'react-router-dom';
import ytAxios from '../../helpers/youtubeAPI';
import {Link} from 'react-router-dom';
import _ from 'lodash';

const styles = {
    root:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    studioLogo: {
        position: 'absolute',
        top: 20,
        height: 20,
    },
    youtubeContainer: {
        width: '90vw',
        height: '80vh',
        position: 'absolute',
        transition: 'all 0.3s',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        margin: 0,
        backgroundColor: '#292929',
        borderRadius: 32,
        boxShadow: '0 15px 30px rgba(0,0,0,30%)',
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        display: 'flex',
        padding: 20,
        "& > *":{
            animation: 'fade 0.5s ease-in-out forwards'
        },
        "&:after":{
            "width": "calc(100% - 100px)",
            "height": "5px",
            "content": "''",
            "position": "absolute",
            "bottom": "10px",
            "boxShadow": "0 -8px 15px 8px #292929"
        }
    },
    youtubeHeader:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        "& img":{
            width: 20,
            height: 20,
        },
        "& span":{
            fontFamily: 'HelveticaCaps',
            fontWeight: 'bold',
            color: '#D8D8D8',
            paddingLeft: 10,
        }
    },
    youtubeInputContainer:{
        width: '70%',
        maxWidth: 550,
        marginTop: 30,
        "& > div":{
            width: '100%',
            position: 'relative',
            "& > input":{
                width: 'calc(100% - 6px)',
                fontFamily: 'HelveticaCaps',
                fontSize: 16,
                fontWeight: 'bold',
                height: 34,
                background: 'none',
                appearance: 'none',
                WebkitAppearance: 'none',
                MozAppearance: 'none',
                outline: 'none',
                border: 'none',
                borderBottom: '1px solid #707070',
                paddingRight: 70,
                color: '#d7d7d7',
                transition: 'border-bottom 0.2s',
                maxWidth: 550,
            },
            "& > button": {
                position: 'absolute',
                right: 0,
                bottom: 0,
                height: 34,
                fontWeight: 'bold',
                fontFamily: 'HelveticaCaps',
                fontSize: 16,
                color: '#D8D8D8',
                backgroundColor: '#707070',
                border: 'none',
                width: 70,
                borderRadius: '6px 6px 6px 0px',
                cursor: 'pointer',
                transition: 'all 0.2s',
                outline: 'none',
            }
        },
    },
    youtubeVideoListContainer:{
        width: 'calc(100% - 100px)',
        height: '100%',
        margin: '0 -17px',
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflowY: 'auto',
        paddingBottom: 50,
        "&::-webkit-scrollbar": {
            "width": "5px"
        },
        "&::-webkit-scrollbar-track": {
            "borderRadius": "10px"
        },
        "&::-webkit-scrollbar-thumb": {
            "background": "#727272",
            "borderRadius": "10px"
        }
    },
    youtubeVideoListItem:{
        position: 'relative',
        flexBasis: 'calc(100% / 5 - 34px)',
        margin: 17,
        borderRadius: '18px 18px 19px 19px',
        cursor:'pointer',
        transition: 'box-shadow 0.2s ease-in-out',
        "& > div":{
            paddingTop: '55%',
        },
        "& img":{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: 19,
        },
        "&:hover":{
            boxShadow: '0 0 0 5px rgba(162,162,162,0.41)'
        },
    },
    videoTitle:{
        "left": "0",
        "width": "100%",
        "color": "#D8D8D8",
        "bottom": "0",
        "position": "absolute",
        "fontFamily": "Helvetica",
        "backgroundColor": "#121212",
        "height": "45px",
        "padding": "12px 20px",
        "overflow": "hidden",
        "textOverflow": "ellipsis",
        "whiteSpace": "nowrap",
        "borderRadius": "0 0 18px 18px",
    },
    noVideosFound:{
        fontFamily: 'HelveticaCaps',
        color: '#d8d8d8',
        textAlign: 'center',
        fontWeight: 'bold',
        width: '100%',
        marginTop: 100,
    },
    "@keyframes fade":{
        "from":{
            opacity: 0,
        },
        "to":{
            opacity: 1,
        }
    },
    '@media screen and (max-width: 1550px)': {
        youtubeVideoListItem:{
            flexBasis: 'calc(100% / 4 - 34px)',
        }
    },
    '@media screen and (max-width: 1300px)': {
        youtubeVideoListItem:{
            flexBasis: 'calc(100% / 3 - 34px)',
        }
    },
    '@media screen and (max-width: 1024px)': {
        youtubeVideoListItem:{
            flexBasis: 'calc(100% / 2 - 34px)',
        }
    },
    '@media screen and (max-width: 750px)': {
        youtubeVideoListItem:{
            flexBasis: 'calc(100% - 34px)',
        }
    },
}

interface IProps{
    classes: any;
}

interface YoutubeVideo{
    id: string;
    thumbnail: string,
    title: string;
}

class Youtube extends Component<IProps>{
    public state: any;
    constructor(props:IProps){
        super(props)
        this.state = {
            handleNext: false,
            inputVal: '',
            videoList: []
        }
        this.handleInput = this.handleInput.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    async componentDidMount(){
        try{
            const videoCall:any = await ytAxios.get('/videos?chart=mostPopular');
            const videos = await videoCall.data;
            if(videos.items){
                const itemsToSet = videos.items.map((el:any)=>{
                    return  {
                        id: el.id,
                        thumbnail: el.snippet.thumbnails.high.url,
                        title: el.snippet.title,
                    } as YoutubeVideo
                })
                this.setState({
                    videoList: itemsToSet,
                })
            }
        } catch(e){
            console.error(e);
        }
    }

    debCall = _.debounce(async ()=>{
        try{
            const queryStr = encodeURIComponent(this.state.inputVal);
            const videoCall:any = await ytAxios.get(`/search?q=${queryStr}`);
            const videos = await videoCall.data;
            console.log(videos)
            if(!videos.error && videos.items && videos.items.length>0){
                const itemsToSet = videos.items.map((el:any)=>{
                    return  {
                        id: el.id.videoId,
                        thumbnail: el.snippet.thumbnails.high.url,
                        title: el.snippet.title,
                    } as YoutubeVideo
                })
                this.setState({
                    videoList: itemsToSet,
                })
            }
        } catch(e){
            console.error(e);
        }
    }, 500);

    handleInput(e:any){
        const inputVal = e.target.value;
        this.setState({
            inputVal,
        },()=>{
            this.debCall()
        })
    }

    handleNext(){
        const {isValidInput, inputVal} = this.state;
        if(isValidInput && inputVal){
            this.setState({
                handleNext: true,
            })
        }
    }

    render(){
        const {classes} = this.props;
        const { handleNext, videoList, inputVal } = this.state;
        return(
            <Layout withLogo>
                {
                    handleNext&&<Redirect to={{
                        pathname: '/home/loader',
                        state: { gifUrl: inputVal }
                    }} push />
                }
                <div className={classes.root}>
                    <div className={classes.youtubeContainer}>
                        <div className={classes.youtubeHeader}>
                            <img src={YoutubeIcon}/>
                            <span>YouTube-დან</span>
                        </div>
                        <div
                            className={classes.youtubeInputContainer}>
                            <div>
                                <input spellCheck={false} onSubmit={this.handleNext} onChange={this.handleInput} onInput={this.handleInput} type="text" placeholder="ჩასვი ვიდეოს ბმული ან აქვე მოძებნე"/>
                                <button onClick={this.handleNext}>GO</button>
                            </div>
                        </div>
                        <div className={classes.youtubeVideoListContainer}>
                            {   videoList.length > 0?
                                videoList.map((el:any)=>{
                                    return(<div key={el.id} className={classes.youtubeVideoListItem}>
                                        <div>
                                            <Link to={`/home/editcrop/${el.id}`}>
                                                <img src={el.thumbnail}/>
                                                <span className={classes.videoTitle}>{el.title}</span>
                                            </Link>
                                        </div>
                                    </div>)
                                })
                                : 
                                <div className={classes.noVideosFound}>
                                    ვიდეოები ვერ მოიძებნა...
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default withStyles(styles)(Youtube)