import ActionTypes from '../constants';
import axios from 'axios';

export const signIn = (accessToken: any, externalId:string) => {
    return (dispatch:any) => {        
        axios.get(`https://api.gifebi.ge/api/Account/SignIn?AuthType=Facebook&AccessToken=${accessToken}&externalId=${externalId}`,
        {
            headers: {
                apikey: '2DAE48B0-47F5-4E6F-8151-29B8278BA40F',
            }
        })
        .then(
            response => response.data
        )
        .then(
            response => dispatch({type : ActionTypes.signIn, payload : response}),
        );    
    };
}


export const getProfile = (accessToken: any) => {
    return (dispatch:any) => {        
        axios.get(`https://api.gifebi.ge/api/Account/Profile`,
        {
            headers: {
                apikey: '2DAE48B0-47F5-4E6F-8151-29B8278BA40F',
                authorization: `Bearer ${accessToken}`,
            }
        })
        .then(
            response => response.data
        )
        .then(
            response => dispatch({type : ActionTypes.getProfile, payload : response}),
        );    
    };
}