import React, { Component } from 'react';
import withStyles from 'react-jss';
import { EditText } from '../../../components/editText';
import Scrollbars from 'react-custom-scrollbars';
import Layers from '../../../components/layers/Layers';
import { Canvas } from '../../../components/canvas';
import { TimelineItem, TimelineControl } from '../../../components/timeline';
import _ from 'lodash';
import { SpeedControl } from '../../../components/speedControl';
import { Controls } from '../../../components/controls';
import { connect } from 'react-redux';
import GifLayer from '../../../components/gifLayer';
import Button from '../../../components/Button';
import StudioLogo from '../../../assets/images/studio-logo.svg';
import { setFrame, setPlaying } from '../../../redux/actions/timeline';
import { Link, Redirect } from 'react-router-dom';
import { STUDIO_API_URL } from '../../../config';
import studioAPI from '../../../helpers/studioAPI';
import AddObjects from '../../../components/addObjects';
import Loader from '../../../assets/images/loader2.svg';

export const sideNavWidth = 320;

const styles = {
    root: {
        backgroundColor: '#121212',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },
    header:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 70,
        padding: '0 35px',
        backgroundColor: '#1C1C1C',
        boxShadow: '0 3px 14px #121212',
        zIndex: 1,
        "& img":{
            height: 20,
        },
        "& button":{
            padding: '8px 25px',
            fontSize: 16,
            borderRadius: 9,
        }
    },
    topControl: {
        width: '100%',
        flexBasis: '70%',
        maxHeight: '70%',
        display: 'flex',
        flexShrink: 0,
    },
    bottomControl: {
        display: 'inline-flex',
        position: 'relative',
        backgroundColor: '#1c1c1c',
        minWidth: '100%',
        height: '30%',
    },
    topLeftControl: {
        width: sideNavWidth,
        height: '100%',
        backgroundColor: '#1c1c1c',
        display: 'flex',
        flexDirection: 'column',
        padding: 35,
    },
    topRightControl: {
        width: `calc(100% - ${sideNavWidth}px)`,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    bottomLeftControl: {
        minWidth: sideNavWidth,
        backgroundColor: '#1c1c1c',
        position: 'sticky',
        borderRight: '1px solid #121212',
    },
    stickyFallback: {
        position: 'webkit-sticky'
    },
    bottomRightControl: {
        height: '100%',
    },
    timelineContainer: {
        backgroundColor: '#1c1c1c',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 'calc(100% - 320px)',
        position: 'relative',
    },
    editTextContainer: {
        borderBottom: '1px solid #121212',
    },
    flexCenter: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    stickyBar: {
        position: 'sticky',
        top: 0,
        height: 50,
        minWidth: '100%',
        display: 'block',
        zIndex: 9999,
        borderBottom: '1px solid #121212',
        backgroundColor: '#3c3c3c',
    },
    canvasContainer: {
        position: 'relative',
        "& img": {
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 1,
            borderRadius: 9,
        },
        "& canvas": {
            position: 'relative',
            zIndex: 2,
        }
    },
    generationOverlay:{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(28, 28, 28, 0.9)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999999,
    }
}

let gifFrames:Array<string> = [];

class Layout extends Component<any> {
    private canvasRef: any;
    private gifContainerRef: any;
    public state: any;
    private scrollerLeft: any;
    private scrollerRight: any;
    private controlsRef: any;
    constructor(props: any) {
        super(props);
        this.state = {
            canvasRefrence: undefined,
            selectedEl: undefined,
            canvasObjects: [],
            scrolledTo: 0,
            scrollLeft: 0,
            barWidth: 7,
            frameCount: 1,
            containerWidth: 0,
            containerHeight: 0,
            isBeingGenerated: false,
            currentFrame: 0,
            genGif: '',
        }
        this.elementSelected = this.elementSelected.bind(this)
        this.onUpdate = this.onUpdate.bind(this)
        this.handleGenerate = this.handleGenerate.bind(this);
        this.controlsRef = React.createRef();
    }
    componentDidMount() {
        const { frameCount, width, height } = this.props;
        const containerWidth = this.gifContainerRef.innerWidth;
        const containerHeight = this.gifContainerRef.innerHeight;
        this.setState({
            canvasRefrence: this.canvasRef,
            frameCount,
            width,
            height,
            containerWidth,
            containerHeight,
        })

        let isSyncingLeftScroll = false;
        let isSyncingRightScroll = false;
        document.querySelectorAll('.scrollerLeft > div:first-of-type').forEach(el => {
            el.addEventListener('scroll', (e: any) => {
                const scrollers = document.querySelectorAll('.scrollerRight > div:first-of-type')
                scrollers.forEach((el) => {
                    const { scrollTop } = e.target;
                    if (!isSyncingLeftScroll) {
                        isSyncingRightScroll = true;
                        el.scrollTop = scrollTop;
                    }
                    isSyncingLeftScroll = false;
                })
            })
        })
        document.querySelectorAll('.scrollerRight > div:first-of-type').forEach(el => {
            el.addEventListener('scroll', (e: any) => {
                const scrollers = document.querySelectorAll('.scrollerLeft > div:first-of-type')
                scrollers.forEach((el) => {
                    const { scrollTop } = e.target;
                    if (!isSyncingRightScroll) {
                        isSyncingLeftScroll = true;
                        el.scrollTop = scrollTop;
                    }
                    isSyncingRightScroll = false;
                })
            })
        })
    }

    componentDidUpdate(prevProps:any, prevState:any) {
        const containerWidth = this.gifContainerRef.clientWidth;
        const containerHeight = this.gifContainerRef.clientHeight;
        if (containerWidth != this.state.containerWidth && containerHeight != this.state.containerHeight) {
            this.setState({
                containerWidth,
                containerHeight,
            })
        }
        if(!_.isEqual(this.props,prevProps) || this.state.isBeingGenerated != prevState.isBeingGenerated){
            const { isBeingGenerated,frameCount } = this.state;
            if(isBeingGenerated){
                setTimeout(()=>{
                    if(this.props.currentFrame >= frameCount-1){
                        const frame = this.canvasRef.canvas.toDataURL('png');
                        gifFrames.push(frame)
                        this.setState({
                            isBeingGenerated: false,
                            currentFrame: 0,
                        },async ()=>{
                            const request = await studioAPI.post('gifs/generate',{
                                gifUrl: this.props.location.state.gifUrl,
                                frames: gifFrames,
                                delay: this.props.frameDelay / this.props.delayDelta,
                            })
                            const resGifUrl = await request.data;
                            this.setState({
                                genGif: `${STUDIO_API_URL}${resGifUrl.gifUrl}`,
                            })
                        })
                    } else {              
                        const frame = this.canvasRef.canvas.toDataURL('png');
                        gifFrames.push(frame);
                        this.props.setFrame(this.props.currentFrame+1);
                    }
                },10)
            }
        }
        if (!_.isEqual(prevProps.width, this.props.width) || !_.isEqual(prevProps.height, this.props.height) || !_.isEqual(prevProps.frameCount, this.props.frameCount)) {
            const { frameCount, width, height } = this.props;
            if (this.props.frameCount != this.props.frameCount
                || this.props.width != this.props.width
                || this.props.height != this.props.height
            ) {
                this.setState({
                    frameCount,
                    width,
                    height,
                })
            }
        }
    }

    elementSelected(element: any) {
        this.setState({
            selectedEl: element
        })
    }
    onUpdate(canvObjs: any) {
        this.setState({
            canvasObjects: canvObjs
        })
    }



    renderThumb({ style, ...props }: any) {
        const thumbStyle = {
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
            height: 180,
            transform: 'translateY(43px)',
            borderRadius: 5,
            width: 4,
        };
        return (
            <div
                style={{ ...style, ...thumbStyle }}
                {...props} />
        );
    }

    renderThumbHorizontal({ style, ...props }: any) {
        const thumbStyle = {
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
            height: 10,
            borderRadius: 5,
            width: 180,
            position: 'relative',
            top: -10,
            cursor: 'pointer',
        };
        return (
            <div
                style={{ ...style, ...thumbStyle }}
                {...props} />
        );
    }

    handleGenerate(){
        gifFrames = [];
        this.props.setPlaying(false);
        this.props.setFrame(0);
        this.setState({
            isBeingGenerated: true,
        })
    }

    render() {
        const { classes } = this.props;
        const { frameCount, width, height, containerWidth, containerHeight, isBeingGenerated, genGif } = this.state;
        return (
            <div className={classes.root}>
                {
                    genGif&&<Redirect to={{
                        pathname: '/home/readygif',
                        state: { gifUrl: genGif }
                    }} />
                }
                {
                    isBeingGenerated && 
                    <div className={classes.generationOverlay}>
                        <img src={Loader}/>
                    </div>
                }                
                <div className={classes.header}>
                    <Link to="/"><Button style="secondary">მთავარი გვერდი</Button></Link>
                    <img src={StudioLogo}/>
                    <Button onClick={this.handleGenerate}>გენერაცია</Button>
                </div>
                <div className={classes.topControl}>
                    <div className={[classes.topLeftControl, classes.editTextContainer].join(' ')}>
                        {
                            this.state.selectedEl && this.state.selectedEl.type ==='text' ?
                            <EditText canvasRef={this.state.canvasRefrence} selectedElRef={this.state.selectedEl} />
                            :
                            <AddObjects frameCount={frameCount} canvasRef={this.state.canvasRefrence} />
                        }
                    </div>
                    <div ref={(ref: any) => { this.gifContainerRef = ref }} className={[classes.topRightControl, classes.flexCenter].join(' ')}>
                        <div className={classes.canvasContainer}>
                            <Canvas containerWidth={containerWidth} containerHeight={containerHeight} width={width} height={height} ref={(ref: any) => { this.canvasRef = ref }} onSelection={this.elementSelected} onUpdate={this.onUpdate} />
                            <GifLayer/>
                        </div>
                        <Controls />
                    </div>
                </div>
                <div className={classes.bottomControl}>
                    <div className={[classes.bottomLeftControl, classes.stickyFallback].join(' ')} style={{ left: this.state.scrollLeft }}>
                        <Scrollbars className="scrollerLeft" renderThumbVertical={() => { return <div></div> }} renderThumbHorizontal={this.renderThumbHorizontal} autoHide>
                            <div className={[classes.stickyBar, classes.stickyFallback].join(' ')}>
                                <SpeedControl />
                            </div>
                            {
                                this.state.canvasRefrence ?
                                    <Layers canvasRef={this.state.canvasRefrence} canvasObjects={this.state.canvasObjects} />
                                    : ""
                            }
                        </Scrollbars>
                    </div>
                    <div className={[classes.bottomRightControl, classes.timelineContainer].join(' ')}>
                        <Scrollbars className="scrollerRight" autoHide={true} renderThumbVertical={this.renderThumb} renderThumbHorizontal={this.renderThumbHorizontal}>
                            <div className={[classes.stickyBar, classes.stickyFallback].join(' ')}>
                                <TimelineControl frameCount={frameCount} />
                            </div>
                            {
                                [...this.state.canvasObjects].reverse().map((x: any) => {
                                    return <TimelineItem canvasRef={this.canvasRef} canvasObj={x} key={x.id} length={frameCount} start={x.start} end={x.end} />
                                })
                            }
                        </Scrollbars>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        width: state.timelineReducer.width,
        height: state.timelineReducer.height,
        frameCount: state.timelineReducer.frameCount,
        currentFrame: state.timelineReducer.currentFrame,
        frameDelay: state.timelineReducer.frameDelay,
        delayDelta: state.timelineReducer.delayDelta,
    }
}

export default connect(mapStateToProps,{setFrame, setPlaying})(withStyles(styles)(Layout));