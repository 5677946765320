import React, { Component } from "react";
import withStyles from 'react-jss';

import EditorSegment from './EditorSegment';
import Pill from '../Pill';
import _ from 'lodash';

//DOTO Add Defaults

// Icons
import BrushIcon from '../icons/IconBrush';

//Images
import noBorder from '../../assets/icons/noborder.svg';
import selectedIcon from '../../assets/icons/selected.svg';

//CSS Variables
const cicrlesPerRow = 5;
const circleSize = 270 / cicrlesPerRow;
const circleMargin = 10;
const absCircleSize = circleSize - circleMargin * 2;
const defaultTextColor = '#ffffff';
const clrGray = '#b2b2b2';

const colorArray = [defaultTextColor, '#000000', '#fc3d4a', '#a468ff', '#5582fe', '#97ff64', '#ffff5d', '#ffb92d', '#bcedff', '#ffbaf6'];

const borderColorArray = [defaultTextColor, '#000000'];

const fontFamilyArray = ['HelveticaCaps', 'Helvetica', 'Banner'];

const styles = {
    textInput: {
        background: 'none',
        border: 'none',
        outline: 'none',
        borderBottom: '1px solid #B2B2B2',
        color: '#B2B2B2',
        width: '100%',
        fontSize: 20,
        paddingBottom: 10,
        fontFamily: 'HelveticaCaps'
    },
    inputContainer: {
        position: 'relative',
    },
    addTextButton: {
        position: 'absolute',
        top: 3,
        right: 5,
        transform: 'rotate(45deg)',
        cursor: 'pointer',
    },
    colorCircleContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: circleSize * cicrlesPerRow,
        margin: `0 -${circleMargin}px`,
        marginTop: 15,
    },
    colorCircle: {
        width: absCircleSize,
        height: absCircleSize,
        margin: `${circleMargin * 0.75}px ${circleMargin}px`,
        borderRadius: '50%',
        cursor: 'pointer',
        position: 'relative',
        backgroundSize: 85,
    },
    colorCircleActive: {
        '&:after': {
            content: `url(${selectedIcon})`,
            position: 'absolute',
            top: -4,
            right: -4,
        }
    },
    fontsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: 'calc(100% + 8px)',
        margin: `0 -4px`,
        marginTop: 15,
    },
    fontPill: {
        margin: 4,
    },
    transparancy: {
        backgroundImage: `url(${noBorder})`,
        backgroundSize: 'contain'
    }
}

class EditText extends Component<any> {
    public state: any;
    constructor(props: any) {
        super(props);
        this.state = {
            textColor: '#f00',
            text: '',
            color: defaultTextColor,
            borderColor: null,
            fontFamily: fontFamilyArray[0],
            fontStyle: 'regular',
        }
        this.addText = this.addText.bind(this);
        this.editText = this.editText.bind(this);
        this.handleChangeColor = this.handleChangeColor.bind(this);
        this.handleChangeText = this.handleChangeText.bind(this);
        this.handleEditBorder = this.handleEditBorder.bind(this);
        this.handleEditFontFamily = this.handleEditFontFamily.bind(this);
        this.handleEditFontStyle = this.handleEditFontStyle.bind(this);

    }
    addText = () => {
        this.props.canvasRef.handlers.addText(this.state.text, this.state.color, this.state.borderColor, this.state.fontFamily, this.state.fontStyle, (Math.random() * 1000).toFixed(0));
    }

    editText = () => {
        this.props.canvasRef.handlers.editText(this.state.text, this.state.color, this.state.borderColor, this.state.fontFamily, this.state.fontStyle);
    }

    handleChangeColor = (color: string) => {
        this.setState({
            color,
        }, () => {
            if (this.props.selectedElRef) {
                this.editText()
            }
        })
    }

    handleChangeText = (event: any) => {
        this.setState({
            text: event.target.value,
        }, () => {
            if (this.props.selectedElRef) {
                this.editText()
            }
        })
    }

    handleEditBorder = (color?: string) => {
        this.setState({
            borderColor: color,
        }, () => {
            if (this.props.selectedElRef) {
                this.editText()
            }
        })
    }

    handleEditFontFamily = (fontFamily?: string) => {
        this.setState({
            fontFamily,
        }, () => {
            if (this.props.selectedElRef) {
                this.editText()
            }
        })
    }

    handleEditFontStyle = (fontStyle?: string) => {
        this.setState({
            fontStyle,
        }, () => {
            if (this.props.selectedElRef) {
                this.editText()
            }
        })
    }

    componentDidMount() {
            if (this.props.selectedElRef) {
                this.setState({
                    text: this.props.selectedElRef.text,
                    color: this.props.selectedElRef.color,
                    borderColor: this.props.selectedElRef.borderColor,
                    fontFamily: this.props.selectedElRef.fontFamily,
                    fontStyle: this.props.selectedElRef.fontStyle,
                })
            }
    }

    componentWillReceiveProps(newProps: any) {
        if (!_.isEqual(newProps, this.props)) {
            if (newProps.selectedElRef) {
                this.setState({
                    text: newProps.selectedElRef.text,
                    color: newProps.selectedElRef.color,
                    borderColor: newProps.selectedElRef.borderColor,
                    fontFamily: newProps.selectedElRef.fontFamily,
                    fontStyle: newProps.selectedElRef.fontStyle,
                })
            } else {
                this.setState({
                    text: '',
                    color: defaultTextColor,
                    borderColor: undefined,
                    fontFamily: fontFamilyArray[0],
                    fontStyle: 'regular',
                })
            }
        }
    }
    render() {
        const { classes } = this.props
        const { color, borderColor } = this.state;
        const TextColorCircle = (props: { color: string, handleClick: any }) => {
            const className = props.color === color ? [classes.colorCircle, classes.colorCircleActive].join(' ') : classes.colorCircle;
            return <div onClick={props.handleClick} className={className} style={{ backgroundColor: props.color }} />
        }
        const BorderColorCircle = (props: { color?: string, handleClick: any, style?: any, classNameProp?: string }) => {
            const className = props.color === borderColor ? [classes.colorCircle, classes.colorCircleActive, props.classNameProp].join(' ') : [classes.colorCircle, props.classNameProp].join(' ');
            return <div onClick={props.handleClick} className={className} style={{ ...props.style, backgroundColor: props.color }} />
        }
        return (
            <div>
                <div className={classes.inputContainer}>
                    <input onInput={this.handleChangeText} onChange={this.handleChangeText} value={this.state.text} className={classes.textInput} placeholder="ტექსტი" type="text" />
 
                </div>
                <EditorSegment title="ფონტი" icon="icon-font">
                    <div className={classes.fontsContainer}>
                        {
                            fontFamilyArray.map(font =>
                                <Pill onClick={() => { this.handleEditFontFamily(font) }} key={font} className={classes.fontPill} active={this.state.fontFamily === font}>
                                    <span style={{ fontFamily: `${font}` }}>ფონტი</span>
                                </Pill>
                            )
                        }
                    </div>
                </EditorSegment>
                <EditorSegment title="ტექსტის ფერი" icon="icon-brush">
                    <div className={classes.colorCircleContainer}>
                        {
                            colorArray.map((color) => <TextColorCircle handleClick={() => { this.handleChangeColor(color) }} key={`text-color-${color}`} color={color} />)
                        }
                    </div>
                </EditorSegment>
                <EditorSegment title="კანტის ფერი" icon="icon-brush">
                    <div className={classes.colorCircleContainer}>
                        {
                            borderColorArray.map((color) => <BorderColorCircle handleClick={() => { this.handleEditBorder(color) }} key={`text-color-${color}`} color={color} />)
                        }
                        <BorderColorCircle classNameProp={classes.transparancy} handleClick={() => { this.handleEditBorder() }} />
                    </div>
                </EditorSegment>
                <EditorSegment title="ტექსტის სტილი" icon="icon-text-style">
                    <div className={classes.fontsContainer}>
                        <Pill onClick={() => { this.handleEditFontStyle('regular') }} className={classes.fontPill} active={this.state.fontStyle === 'regular'}>
                            <span style={{ fontFamily: 'Helvetica' }} >Regular</span>
                        </Pill>
                        <Pill onClick={() => { this.handleEditFontStyle('bold') }} className={classes.fontPill} active={this.state.fontStyle === 'bold'}>
                            <span style={{ fontFamily: 'Helvetica', fontWeight: 'bold' }}>Bold</span>
                        </Pill>
                        <Pill onClick={() => { this.handleEditFontStyle('italic') }} className={classes.fontPill} active={this.state.fontStyle === 'italic'}>
                            <span style={{ fontFamily: 'Helvetica', fontStyle: 'italic' }}>Italic</span>
                        </Pill>
                    </div>
                </EditorSegment>
            </div>
        )
    }
}

export default withStyles(styles)(EditText)