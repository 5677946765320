import React, { Component } from 'react';
import withStyles from 'react-jss';
import { connect } from 'react-redux';

const styles = {
    container: {
        display: 'inline-flex',
        borderBottom: '1px solid #1c1c1c',
        "box-sizing": 'border-box',
        margin: '5px 0',
        minWidth: '100%',
        backgroundColor: '#161616',
    },
    bar: {
        height: 36,
        backgroundColor: '#414141',
        borderLeft: '1px solid #1c1c1c',
        "user-select": 'none',
        "box-sizing": 'border-box',
        cursor: 'pointer',
        flexShrink: 0,
    },
    barActive: {
        height: 36,
        backgroundColor: '#B2B2B2',
        borderLeft: '1px solid #1c1c1c',
        cursor: 'pointer',
        "user-select": 'none',
        "box-sizing": 'border-box',
        flexShrink: 0,
        borderRadius: 2,
    },
    barDisabled: {
        height: 36,
        backgroundColor: '#161616',
        borderLeft: '1px solid #1c1c1c',
        "user-select": 'none',
        "box-sizing": 'border-box',
        cursor: 'pointer',
        flexShrink: 0,
    },
}

interface IProps {
    length: number;
    start: number;
    end: number;
    barWidth?: number;
    classes?: any,
    canvasObj: any;
    canvasRef: any;
}

class TimelineItem extends Component<IProps> {
    public state: any;
    private slider: HTMLDivElement;
    public startingPosition: any;
    public initialStart: any;
    public initialEnd: any;
    constructor(props: any) {
        super(props)
        this.state = {
            barWidth: 0,
            barSize: 0,
            activeStart: 0,
            activeEnd: 0,
            isActive: false,
            isStartActive: false,
            isWholeDrag: false,
            isDragging: false,
        }
        this.initialStart = undefined;
        this.initialEnd = undefined;
        this.startingPosition = 0;
        this.resize = this.resize.bind(this);
        this.setActive = this.setActive.bind(this);
        this.setInactive = this.setInactive.bind(this);
    }

    componentWillMount() {
        this.setState({
            barSize: this.props.length,
            activeStart: this.props.start,
            activeEnd: this.props.end,
            barWidth: 4,
        })
    }

    componentDidMount(){
        this.setState({
            barSize: this.props.length,
            activeStart: this.props.start,
            activeEnd: this.props.end,
            barWidth: (window.innerWidth-320)/(this.props.length||100) > 5 ? (window.innerWidth-320)/(this.props.length||100) : 5,
        },()=>{
            this.checkIfVisible(this.props)
        })
    }

    componentWillReceiveProps(newProps: IProps) {
        this.setState({
            barSize: newProps.length,
            activeStart: newProps.start,
            activeEnd: newProps.end,
            barWidth: (window.innerWidth-320)/(newProps.length||100) > 5 ? (window.innerWidth-320)/(newProps.length||100) : 5,
        },()=>{
            this.checkIfVisible(newProps)
        })
    }

    checkIfVisible(props:any){
        const { canvasRef, canvasObj} = props;
        const {activeStart, activeEnd } = this.state;
        const { currentFrame } = props.timelineReducer;
        if( currentFrame >= activeStart && currentFrame <= activeEnd-1 ){
            canvasRef.handlers.show(canvasObj.obj);
        } else {
            canvasRef.handlers.hide(canvasObj.obj);
        }
    }

    resize(event: MouseEvent) {
        const { isActive, isStartActive, isWholeDrag } = this.state;
        if (isActive && !isWholeDrag) {
            var childArray = Array.prototype.slice.call(this.slider.children)
            childArray.map((el, i) => {
                const { left } = el.getBoundingClientRect()
                if (event.clientX >= left && event.clientX <= left + el.offsetWidth) {
                    if (isStartActive && i < this.state.activeEnd) {
                        this.setState({
                            activeStart: i,
                        },()=>{
                            this.checkIfVisible(this.props)
                            this.props.canvasRef.handlers.update(this.props.canvasObj,{start: this.state.activeStart, end: this.state.activeEnd})
                        })
                    } else if (i >= this.state.activeStart) {
                        this.setState({
                            activeEnd: i + 1,
                        },()=>{
                            this.checkIfVisible(this.props)
                            this.props.canvasRef.handlers.update(this.props.canvasObj,{start: this.state.activeStart, end: this.state.activeEnd})
                        })
                    }
                }
            })
        } else if (isActive && isWholeDrag) {
            const { activeStart, activeEnd, barSize, barWidth } = this.state;
            const clientX = event.clientX;
            if (!this.state.isDragging) {
                this.startingPosition = clientX;
                this.initialStart = activeStart;
                this.initialEnd = activeEnd;
                this.setState({
                    isDragging: true,
                })
            }

            let gotoStart = Math.round((clientX - this.startingPosition) / barWidth) + this.initialStart;
            let gotoEnd = Math.round((clientX - this.startingPosition) / barWidth) + this.initialEnd;
            const currLength = activeEnd - activeStart;
            if (gotoStart < 0) {
                gotoStart = 0;
                gotoEnd = currLength;
            }
            if (gotoEnd > barSize) {
                gotoEnd = barSize;
                gotoStart = barSize - currLength;
            }
            requestAnimationFrame(() => {
                this.setState({
                    activeStart: gotoStart,
                    activeEnd: gotoEnd,
                },()=>{
                    this.checkIfVisible(this.props)
                    this.props.canvasRef.handlers.update(this.props.canvasObj,{start: this.state.activeStart, end: this.state.activeEnd})
                })
            })
        }
    }
    removeMouseUpEvent = () => {
        window.removeEventListener('mousemove', this.resize);
        window.removeEventListener('mouseup', this.removeMouseUpEvent);
        this.setState({
            isDragging: false,
        })
    }
    setActive(isStart: boolean, isWholeDrag: boolean) {
        this.setState({
            isActive: true,
            isStartActive: isStart,
            isWholeDrag,
        })
        window.addEventListener('mousemove', this.resize);
        window.addEventListener('mouseup', this.removeMouseUpEvent);
    }
    setInactive() {
        this.setState({
            isActive: false,
            isWholeDrag: false,
        })
        this.setState({
            isDragging: false,
        })
        window.removeEventListener('mousemove', this.resize);
    }
    render() {
        const { activeStart, activeEnd, barWidth } = this.state;
        const { classes } = this.props;
        return (
            <div ref={(slider: HTMLDivElement) => this.slider = slider}
                onMouseUp={this.setInactive}
                className={classes.container}>
                {
                    Array(this.state.barSize).fill(null).map((_, i) => {
                        if (i === activeStart || i === activeEnd - 1) {
                            const isStart = i === activeStart;
                            return (
                                <span
                                    key={i}
                                    onMouseDown={() => this.setActive(isStart, false)}
                                    style={{ width: barWidth }}
                                    className={classes.barActive} />
                            )
                        } else if (i > activeStart && i < activeEnd - 1) {
                            return (
                                <span
                                    key={i}
                                    onMouseDown={() => this.setActive(false, true)}
                                    style={{ width: barWidth }}
                                    className={classes.bar} />
                            )
                        } else {
                            return (
                                <span
                                    key={i}
                                    style={{ width: barWidth }}
                                    className={classes.barDisabled} />
                            )
                        }
                    })
                }
            </div>
        )
    }
}

const mapStateToProps = (state:any) => ({
    ...state
})

export default connect(mapStateToProps)(withStyles(styles)(TimelineItem))