import React, { Component } from 'react';
import withStyles from 'react-jss';
import Layout from './layout/Layout';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import gifFrames from 'gif-frames';
import { setFrameCount, setFrameDelay, setDimensions } from '../../redux/actions/timeline';
import { setGifFrames } from '../../redux/actions/gif';
import _ from 'lodash';
import Button from '../../components/Button';
import {Link} from 'react-router-dom';

const styles = {
    root:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loaderContainer:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& span':{
            fontFamily: 'HelveticaCaps',
            fontSize: 18,
            fontWeight: 'bold',
            color: '#B2B2B2',
        }
    },
    progressContainer:{
        width: '30vw',
        height: 5,
        position: 'relative',
        marginTop: 20,
        backgroundColor: '#D8D8D8',
    },
    progress:{
        position: 'absolute',
        height: 5,
        width: 0,
        backgroundColor: '#FC3D4A',
        boxShadow: '0 0 5px #FC3D4A',
        top: 0,
        left: 0,
    },
    onError:{
        "color": "#d8d8d8",
        "fontSize": "22px",
        "textAlign": "center",
        "fontFamily": "HelveticaCaps",
        "backgroundColor": "#292929",
        "padding": "50px",
        "borderRadius": "16px",
        "lineHeight": "35px",
    }
}

interface IProps{
    classes: any;
    timelineReducer: any;
    setFrameCount: Function;
    setFrameDelay: Function;
    setDimensions: Function;
    setGifFrames: Function;
    location: any;
}

const waitFor = (ms:number) => new Promise(r => setTimeout(r, ms));

async function asyncForEach(array:any, callback:Function) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
}

class Upload extends Component<IProps>{
    public state: any;
    constructor(props:IProps){
        super(props)
        this.state = {
            handleNext: false,
            frames: [],
            frameDelays: [],
            loadPercent: 0,
            invalidUrl: false,
            didError: false,
        }
        this.handleNext = this.handleNext.bind(this);
    }

    handleNext(){
        this.setState({
            handleNext: true,
        })
    }

    componentWillMount(){
        if(!(this.props.location.state && this.props.location.state.gifUrl)){
            this.setState({
                invalidUrl: true,
            })
        }
    }

    async componentDidMount(){
        const _self = this;
        if(this.props.location.state && this.props.location.state.gifUrl){
            gifFrames({ url: this.props.location.state.gifUrl, frames: 'all', outputType: 'canvas', cumulative: true })
            .then(function (frameData:any) {
                asyncForEach(frameData, async (element:any, i:number) => {
                    await waitFor(1);
                    let imageToAppend = new Image();
                    imageToAppend.src = element.getImage().toDataURL();
                    _self.setState({
                        frames: [..._self.state.frames, ...[imageToAppend]],
                        frameDelays: [..._self.state.frameDelays, ...[element.frameInfo.delay]]
                    },()=>{
                        _self.setState({
                            loadPercent: Math.round((i+1)/frameData.length*100),
                        })
                        if(i >= frameData.length-1){
                            var mostFrequentDelay:any = _.head(_(_self.state.frameDelays)
                            .countBy()
                            .entries()
                            .maxBy('[1]'));
                            _self.props.setFrameCount(_self.state.frames.length);
                            _self.props.setFrameDelay(mostFrequentDelay*10);
                            _self.props.setDimensions({width: frameData[0].frameInfo.width, height: frameData[0].frameInfo.height})
                            _self.props.setGifFrames(_self.state.frames)
                            _self.setState({
                                handleNext: true,
                            })
                        }
                    });
                })
            }).catch(()=>{
                this.setState({
                    didError: true,
                })
            });
        }
    }

    render(){
        const {classes} = this.props;
        const { handleNext,loadPercent,invalidUrl,didError } = this.state;
        return(
            <Layout withLogo>
                {
                    invalidUrl&&<Redirect to={{
                        pathname: '/',
                    }}/>
                }
                {
                    handleNext&&<Redirect to={{
                        pathname: '/editor',
                        state: { gifUrl: this.props.location.state.gifUrl }
                    }}/>
                }
                {!didError?
                <div className={classes.root}>
                    <div className={classes.loaderContainer}>
                        <span>იტვირთება...</span>
                        <div className={classes.progressContainer}>
                            <div style={{width: `${loadPercent}%`}} className={classes.progress}/>
                        </div>
                    </div>
                </div>:
                <div className={classes.onError}>
                    <span style={{color: '#FC3D4A'}}>GIF</span>-ი ვერ ჩავტვირთე <br/> <span style={{fontSize: 25, padding: '40px 0', display: 'block'}}>😞</span> <Link to="/"><Button style="secondary">სხვა გიფი სცადე</Button></Link>
                </div>
                }
            </Layout>
        )
    }
}

const mapStateToProps = (state:any) => {
    return {
        timelineReducer: state.timelineReducer,
    }
}

export default connect(mapStateToProps,  { setFrameCount, setFrameDelay, setDimensions, setGifFrames })(withStyles(styles)(Upload));