import React, { Component } from 'react';
import withStyles from 'react-jss';
import StudioLogo from '../../../assets/images/studio-logo.svg'
import LinkIcon from '../../../assets/icons/link.svg';
import UploadIcon from '../../../assets/icons/upload.svg';
import YoutubeIcon from '../../../assets/icons/youtube.svg';
import { Redirect } from 'react-router-dom';


const styles = {
    actionContainer:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    action: {
        width: 165,
        height: 165,
        backgroundColor: '#292929',
        borderRadius: 32,
        boxShadow: '0 15px 30px rgba(0,0,0,30%)',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 20,
        margin: '0 35px',
        "& img":{
            height: 60,
        },
        "& span":{
            fontFamily: 'HelveticaCaps',
            fontWeight: 'bold',
            color: '#D8D8D8',
            marginTop: 25,
        },
    },
    copyright:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "& span":{
            color: '#808080',
            fontFamily: 'Helvetica',
        }
    },
    uploadActive: {
        animation: 'uploadAnim 0.5s forwards',
        "& img":{
            opacity: 0,
        },
        "& span":{
            opacity: 0,
        },
    },
    linkActive: {
        animation: 'linkAnim 0.5s forwards',
        "& img":{
            opacity: 0,
        },
        "& span":{
            opacity: 0,
        },
    },
    youtubeActive: {
        animation: 'youtubeAnim 0.5s forwards',
        "& img":{
            opacity: 0,
        },
        "& span":{
            opacity: 0,
        },
    },
    "@keyframes uploadAnim":{
        "from":{
            width: 165,
            height: 165,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(calc(-50% - 235px), -50%)',
        },
        "to":{
            width: '65vmin',
            height: '65vmin',
            position: 'absolute',
            transition: 'all 0.3s',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: 0,
        }
    },
    "@keyframes linkAnim":{
        "from":{
            width: 165,
            height: 165,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        "to":{
            width: '65vmin',
            height: 250,
            position: 'absolute',
            transition: 'all 0.3s',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: 0,
        }
    },
    "@keyframes youtubeAnim":{
        "from":{
            width: 165,
            height: 165,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(calc(-50% + 235px), -50%)',
        },
        "to":{
            width: '90vw',
            height: '80vh',
            position: 'absolute',
            transition: 'all 0.3s',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: 0,
        }
    },
}

interface IProps{
    classes?: any;
    history?: any;
    onSelected: Function;
}

class UploadSelector extends Component<IProps>{
    public state: any;
    constructor(props:IProps){
        super(props)
        this.setActive = this.setActive.bind(this);
        this.state={
            uploadActive: false,
            linkActive: false,
            youtubeActive: false,
            redirect: false,
        }
    }
    setActive(index:number){
        switch(index){
            case 0:
                this.setState({
                    uploadActive: true,
                },()=>{
                    let tempTimeout = setTimeout(()=>{
                        this.setState({
                            redirect: '/home/upload',
                        },()=>{
                            clearTimeout(tempTimeout);
                        })
                    },500)
                })
                break;
            case 1:
                this.setState({
                    linkActive: true,
                },()=>{
                    let tempTimeout = setTimeout(()=>{
                        this.setState({
                            redirect: '/home/link',
                        },()=>{
                            clearTimeout(tempTimeout);
                        })
                    },500)
                })
                break;
            case 2:
                this.setState({
                    youtubeActive: true,
                },()=>{
                    let tempTimeout = setTimeout(()=>{
                        this.setState({
                            redirect: '/home/youtube',
                        },()=>{
                            clearTimeout(tempTimeout);
                        })
                    },500)
                })
                break;
            default:
                break;
        }
        this.props.onSelected();
    }
    render(){
        const { classes } = this.props;
        const { uploadActive,linkActive,youtubeActive,redirect } = this.state;
        return(
            <div className={classes.actionContainer}>
                {
                    redirect && <Redirect to={redirect} push />
                }
                <div onClick={()=>{this.setActive(0)}} className={(uploadActive?[classes.action, classes.uploadActive].join(' '):classes.action)}>
                    <img src={UploadIcon}/>
                    <span>ატვირთე</span>
                </div>
                <div onClick={()=>{this.setActive(1)}} className={(linkActive?[classes.action, classes.linkActive].join(' '):classes.action)}>
                    <img src={LinkIcon}/>
                    <span>ჩააგდე URL</span>
                </div>
                <div onClick={()=>{this.setActive(2)}} className={(youtubeActive?[classes.action, classes.youtubeActive].join(' '):classes.action)}>
                    <img src={YoutubeIcon}/>
                    <span>YouTube-დან</span>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(UploadSelector)