import React, { Component } from 'react';
import fabric from './FabricOverrides';
import _ from 'lodash';

const canvasId = "editor-canvas";

var HideControls = {
    'tl': true,
    'tr': true,
    'br': true,
    'bl': true,
    'ml': false,
    'mt': false,
    'mr': false,
    'mb': false,
    'mtr': false
};



let canvasOption = {
    preserveObjectStacking: true,
    selection: true,
    defaultCursor: 'default',
    backgroundColor: 'transparent',
};

const defaultOptions = {
    strokeWidth: 6,
}

interface IProps {
    onSelection?: any;
    onUpdate?: any;
    ref: any;
    width: number,
    height: number,
    containerWidth: number,
    containerHeight: number,
}

class Canvas extends Component<IProps> {
    private canvasRef: any;
    private canvas: fabric.Canvas;
    public canvasObjects: Array<any>;
    public selectedEl: string;
    public selectedObj: number;
    public timeline: any;
    public timeout: any;
    constructor(props: any) {
        super(props);
        this.canvasRef = React.createRef();
        this.canvasObjects = [];
        this.timeline = 0;
        this.timeout = undefined;
    }

    componentDidMount() {
        const {width,height} = this.props;
        canvasOption = {...canvasOption, ...{width, height}}
        this.canvas = new fabric.Canvas(canvasId, canvasOption);
        this.canvas.setBackgroundColor(canvasOption.backgroundColor, this.canvas.renderAll.bind(this.canvas));
        this.canvas.on('selection:created', () => {
            this.eventHandlers.onSelection()
        })
        this.canvas.on('selection:updated', () => {
            this.eventHandlers.onSelection()
        })
        this.canvas.on('selection:cleared', () => {
            this.eventHandlers.onSelection()
        })
        this.canvas.on('object:removed', (e) => {
            _.remove(this.canvasObjects, {
                obj: e.target
            } as any);
            this.handlers.setActiveObject()
            this.props.onUpdate(this.canvasObjects);
        })
        const _self = this;
        this.canvas.on('text:changed', function(e:any) {
            const changedObj:any = _self.canvasObjects[_self.canvasObjects.findIndex((x:any) => x.obj === e.target)];
            changedObj.text = e.target.text;
            _self.canvas.renderAll();
            _self.props.onUpdate(_self.canvasObjects);
        });
    }

    componentDidUpdate(prevProps:any,prevState:any){
        if(
            (this.props.height !== prevProps.height && this.props.width !== prevProps.width)
            // ||
            // (this.props.containerWidth != prevProps.containerWidth && this.props.containerHeight != prevProps.containerHeight)
        ){
            const {width,height,containerWidth, containerHeight} = this.props;
            // let heightToSet = 0;
            // let widthToSet = 0;
            // const resizeDelta = Math.min((containerWidth*0.8 / width) ,((containerHeight-180)/height))
            // heightToSet = Math.round(height * resizeDelta);
            // widthToSet = Math.round(width * resizeDelta);
            // this.canvas.setHeight(heightToSet);
            // this.canvas.setWidth(widthToSet);
            this.canvas.setHeight(height);
            this.canvas.setWidth(width);
            this.canvas.renderAll();
            this.canvas.calcOffset();
            this.canvas.renderAll();
        }
    }

    eventHandlers = {
        onSelection: () => {
            this.handlers.setActiveObject()
        }
    }
    handlers = {
        update: (canvObject: any, updatedObj: any) => {
            canvObject.start = updatedObj.start;
            canvObject.end = updatedObj.end;
            this.props.onUpdate(this.canvasObjects);
        },
        hide: (canvObject?: any) => {
            canvObject.set({ selectable: false });
            canvObject.set({ lockMovementX: true });
            canvObject.set({ lockMovementY: true });
            canvObject.set({ visible: false });
            this.canvas.discardActiveObject();
            this.canvas.renderAll();
        },
        show: (canvObject: any) => {
            canvObject.set({ selectable: true });
            canvObject.set({ lockMovementX: false });
            canvObject.set({ lockMovementY: false });
            canvObject.set({ visible: true });
            this.canvas.renderAll();
        },
        setActiveObject: (canvObject?: any) => {
            var activeObject = this.canvas.getActiveObject()
            if (canvObject) {
                activeObject = canvObject;
                this.canvas.setActiveObject(activeObject);
                this.canvas.renderAll();
            }
            if (activeObject) {
                this.selectedObj = this.canvasObjects.findIndex(x => x.obj == activeObject);
                this.props.onSelection(this.canvasObjects[this.selectedObj]);
            } else {
                this.selectedObj = -1;
                this.props.onSelection(null);
            }
            this.props.onUpdate(this.canvasObjects);
        },
        removeObject: (object: any) => {
            this.canvas.remove(object);
            this.props.onUpdate(this.canvasObjects);
        },
        addText: (text: string, id: string, end: number) => {
            let newText = new fabric.IText(text, {
                fill: 'rgb(255, 255, 255)',
                fontFamily: 'HelveticaCaps',
                cornerSize: 30,
                padding: 10,
                borderDashArray: [5, 3],
                borderColor: 'rgba(171, 171, 171, 0.7)',
                paintFirst: "stroke",
                objectCaching: false,
                id: id,
            })
            newText.setControlsVisibility(HideControls);
            this.canvasObjects.push({
                id,
                obj: newText,
                text,
                color: '#ffffff',
                borderColor: '',
                start: 0,
                end: end || 1,
                fontFamily: 'HelveticaCaps',
                fontStyle: 'regular',
                type: 'text',
            })
            var elementToPush = this.canvasObjects.findIndex(x => x.id == id);
            this.canvas.add(this.canvasObjects[elementToPush].obj);
            this.canvas.setActiveObject(this.canvasObjects[elementToPush].obj);
            newText.center();
            this.props.onUpdate(this.canvasObjects);
        },
        addSticker: (sticker: string, id: string, end: number) => {
            const _self = this;
            new fabric.Image.fromURL(sticker, function(newSticker:any) {
                const scale = _self.props.width / newSticker.width / 4;
                newSticker.set({ 
                    cornerSize: 30,
                    padding: 10,
                    borderDashArray: [5, 3],
                    borderColor: 'rgba(171, 171, 171, 0.7)',
                    scaleX: scale,
                    scaleY: scale,
                });
                newSticker.setControlsVisibility(HideControls);
                _self.canvasObjects.push({
                    id,
                    obj: newSticker,
                    start: 0,
                    end: end || 1,
                    type: 'sticker',
                    stickerSrc: sticker,
                })
                var elementToPush = _self.canvasObjects.findIndex(x => x.id == id);
                _self.canvas.add(_self.canvasObjects[elementToPush].obj);
                _self.canvas.setActiveObject(_self.canvasObjects[elementToPush].obj);
                newSticker.center();
                _self.props.onUpdate(_self.canvasObjects);
            });
        },
        editText: (text: string, color: string, borderColor: string, fontFamily: string, fontStyle: string) => {
            if (this.selectedObj != null && this.selectedObj != -1) {
                this.canvasObjects[this.selectedObj].text = text;
                this.canvasObjects[this.selectedObj].color = color;
                this.canvasObjects[this.selectedObj].borderColor = borderColor;
                this.canvasObjects[this.selectedObj].fontFamily = fontFamily;
                this.canvasObjects[this.selectedObj].fontStyle = fontStyle;
                let instance = this.canvasObjects[this.selectedObj].obj;
                instance.set({ text });
                instance.set({ fill: color });
                if (borderColor) {
                    instance.set({ strokeWidth: defaultOptions.strokeWidth });
                    instance.set({ stroke: borderColor });
                } else {
                    instance.set({ strokeWidth: null });
                }
                switch (fontStyle) {
                    case 'bold':
                        instance.set({ fontWeight: 'bold' });
                        instance.set({ fontStyle: 'normal' });
                        break;
                    case 'italic':
                        instance.set({ fontWeight: 'normal' });
                        instance.set({ fontStyle: 'italic' });
                        break;
                    default:
                        instance.set({ fontWeight: 'normal' });
                        instance.set({ fontStyle: 'normal' });
                        break;

                }
                instance.set({ fontFamily });
            }
            this.props.onUpdate(this.canvasObjects);
            this.canvas.renderAll();
        },
        playTimeline: () => {
            clearInterval(this.timeout);
            this.timeout = setInterval(() => {
                this.timeline += 1;
                if (this.timeline > 20) {
                    this.timeline = 0;
                }
                this.canvasObjects.forEach((el, i) => {
                    if (this.timeline >= el.start && this.timeline <= el.end) {
                        el.obj.set({
                            opacity: 1,
                            selectable: true
                        });
                    } else {
                        el.obj.set({
                            opacity: 0,
                            selectable: false
                        });
                    }
                })
                this.canvas.renderAll();
            }, 25)
        },
        stopTimeline: () => {
            clearInterval(this.timeout);
            this.canvas.renderAll();
        }
    }

    render() {
        return (
            <div id="editor-canvas-container">
                <canvas id={canvasId} ref={this.canvasRef}></canvas>
            </div>
        )
    }
}

export default Canvas;