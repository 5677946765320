import ActionTypes from '../constants';

interface Dimensions {
    width: number;
    height: number;
}

export const setDimensions = (dimensions: Dimensions) => {
    return({
        type: ActionTypes.setDimensions,
        payload: {
            dimensions,
        }
    })
}

export const setPlaying = (isPlaying: boolean) => {
    return({
        type: ActionTypes.setPlaying,
        payload: {
            isPlaying,
        }
    })
}

export const setFrameDelay = (frameDelay: number) => {
    return({
        type: ActionTypes.setFrameDelay,
        payload: {
            frameDelay,
        }
    })
}

export const setFrameCount = (frameCount:number) => {
    return({
        type: ActionTypes.setFrameCount,
        payload: {
            frameCount,
        }
    })
}

export const setFrame = (frame:number) => {
    return ({
            type: ActionTypes.setFrame,
            payload: {
                frame,
            }
        }
    )
}

export const setDelayDelta = (delayDelta:number) => {
    return ({
            type: ActionTypes.setDelayDelta,
            payload: {
                delayDelta,
            }
        }
    )
}
