import { combineReducers } from 'redux';
import timelineReducer from './timelineReducer';
import gifReducer from './gifReducer';
import authReducer from './authReducer';


export default combineReducers({
    timelineReducer,
    gifReducer,
    authReducer
});