import ActionTypes from '../constants';


export const setGifFrames = (frames: any) => {
    return({
        type: ActionTypes.setFrames,
        payload: {
            frames,
        }
    })
}
