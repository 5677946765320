import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

interface IProps{
    timelineReducer: any;
    gifReducer: any;
}

class GifLayer extends Component<IProps>{
    public state: any;
    constructor(props:IProps){
        super(props)
        this.state={
            frames:[],
        }
    }

    render(){
        const {currentFrame} = this.props.timelineReducer;
        const { frames } = this.props.gifReducer;
        return(
            <div>
                {
                    frames.length > 0
                    ?
                    <img src={frames[currentFrame].src}/>
                    :
                    <img/>
                }
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return {
        ...state,
    }
}

export default connect(mapStateToProps)(GifLayer);