import { fabric } from 'fabric';

var HideControls = {
  'tl': true,
  'tr': true,
  'bl': true,
  'br': true,
  'ml': false,
  'mt': false,
  'mr': false,
  'mb': false,
  'mtr': false
};

var dataImage = [
  "https://cdn1.iconfinder.com/data/icons/streamline-interface/60/cell-8-10-120.png",
  "https://cdn1.iconfinder.com/data/icons/ui-color/512/Untitled-12-128.png",
  "https://cdn2.iconfinder.com/data/icons/social-messaging-productivity-1/128/sync-16.png",
  "https://cdn2.iconfinder.com/data/icons/social-messaging-productivity-1/128/write-compose-16.png",
  "https://cdn3.iconfinder.com/data/icons/social-messaging-productivity-1/128/save-16.png"
];

import TrashIcon from '../../assets/icons/trash.svg';
import ExpandIcon from '../../assets/icons/expand.svg';
import RotateIcon from '../../assets/icons/sync.svg';
import RotateFlipIcon from '../../assets/icons/expandflip.svg';


var TrashIconImage = new Image();
TrashIconImage.src = TrashIcon;

var ExpandIconImage = new Image();
ExpandIconImage.src = ExpandIcon;

var RotateIconImage = new Image();
RotateIconImage.src = RotateIcon;

var RotateFlipIconImage = new Image();
RotateFlipIconImage.src = RotateFlipIcon;


const modFabric = fabric as any;

//modFabric.devicePixelRatio = 2;

modFabric.Object.prototype._drawControl = function (control: any, ctx: any, methodName: any, left: any, top: any) {
  if (!this.isControlVisible(control)) {
    return;
  }
  var SelectedIconImage = new Image();
  var size = this.cornerSize;
  /*  fabric.isVML() ||*/
  this.transparentCorners || ctx.clearRect(left, top, size, size);
  switch (control) {
    case 'tl':
      /*delete*/
      SelectedIconImage = TrashIconImage;
      break;
    case 'tr':
      /*scale*/
      SelectedIconImage = RotateFlipIconImage;
      break;
    case 'bl':
      /*scale*/
      SelectedIconImage = RotateIconImage
      break;
    case 'br':
      /*rotate*/
      SelectedIconImage = ExpandIconImage
      break;
    default:
      break;
    //ctx[methodName](left, top, size, size);
  }

  if (control == 'tr' || control == 'tl' || control == 'bl' || control == 'br') {
    try {
      ctx.drawImage(SelectedIconImage, left, top, 30, 30);
    } catch (e) {
      //ctx[methodName](left, top, size, size);
    }
  }
}

modFabric.Canvas.prototype._setCursorFromEvent = function (e: any, target: any) {
  if (!target) {
    this.setCursor(this.defaultCursor);
    return false;
  }

  var hoverCursor = target.hoverCursor || this.hoverCursor,
    activeSelection = this._activeObject && this._activeObject.type === 'activeSelection' ?
      this._activeObject : null,
    // only show proper corner when group selection is not active
    corner = (!activeSelection || !activeSelection.contains(target))
      && target._findTargetCorner(this.getPointer(e, true));

  if (!corner) {
    this.setCursor(hoverCursor);
  }
  else {
    this.setCursor('pointer');
  }
},

  modFabric.Canvas.prototype._getActionFromCorner = function (target: any, corner: any) {
    var action = 'drag';
    if (corner) {
      switch (corner) {
        case 'ml':
        case 'mr':
          action = 'scaleX';
          break;
        case 'mtr':
          action = 'rotate';
          break;
        /**ADD **/
        case 'tr':
        case 'br':
          action = 'scale';
          break;
        case 'bl':
          action = 'rotate';
          break;
        case 'tl': //delete function if mouse down
          action = 'delete';
          this.remove(this.getActiveObject());
          break;
        /**ADD END**/
        default:
          action = 'scale';
      }
    }
    return action;
  }



// modFabric.Canvas.prototype._performTransformAction = function(e:any, transform:any, pointer:any) {
//     var x = pointer.x,
//       y = pointer.y,
//       target = transform.target,
//       action = transform.action;
//     if (action === 'rotate') {
//       this._rotateObject(x, y);
//       this._fire('rotating', target, e);
//     } else if (action === 'scale') {
//       this._onScale(e, transform, x, y);
//       this._fire('scaling', target, e);
//     } else if (action === 'scaleX') {
//       this._scaleObject(x, y, 'x');
//       this._fire('scaling', target, e);
//     } else if (action === 'scaleY') {
//       this._scaleObject(x, y, 'y');
//       this._fire('scaling', target, e);
//     }
//     /**ADD**/
//     else if (action === 'delete') {
//       //do nothing, because here function executed when mouse moves
//     }
//     /**ADD END**/
//     else {
//       this._translateObject(x, y);
//       this._fire('moving', target, e);
//       this.setCursor(this.moveCursor);
//     }
//   }
//********override END*****//

export default modFabric;