import React, { Component } from 'react';
import widthStyles from 'react-jss';
import Button from '../Button';
import { IconFont } from '../../components/icons';

interface IProps{
    classes: any,
    canvasRef: any,
    frameCount: number,
}

const styles = {
    root:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    HeaderTyp:{
        fontFamily: 'HelveticaCaps',
        fontWeight: 'bold',
        fontSize: 18,
        color: '#d8d8d8',
    },
    hr:{
        width: '100%',
        height: 1,
        borderBottom: '1px solid #707070',
        marginTop: 20,
    },
    btnAction:{
        width: '100%',
        marginTop: 20,
        flexShrink: 0,
    },
    btnIcon: {
        display: 'inline',
        verticalAlign: 'middle',
        marginRight: 20,
    },
    stickerContainer:{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: 20,
        overflowY: 'auto',
        "& div":{
            flexBasis: '25%',
            padding: '10px 0'
        },
        "& img":{
            width: '100%',
            height: 50,
            objectFit: 'contain',
            cursor: 'pointer',
            filter: 'drop-shadow(4px 4px 0px #000)',
        },
        "&::-webkit-scrollbar": {
            "width": "5px"
        },
        "&::-webkit-scrollbar-track": {
            "borderRadius": "10px"
        },
        "&::-webkit-scrollbar-thumb": {
            "background": "#727272",
            "borderRadius": "10px"
        }
    }
}

class AddObjects extends Component<IProps>{
    public state: any;
    constructor(props:IProps){
        super(props)
        this.state={
            stickers: [],
            stickerBarActive: false,
        }
    }

    toggleStickers = () =>{
        this.setState({
            stickerBarActive: !this.state.stickerBarActive,
        })
    }

    componentWillMount(){
        const stickers = [
            '/stickers/1.png',
            '/stickers/2.png',
            '/stickers/3.png',
            '/stickers/4.png',
            '/stickers/5.png',
            '/stickers/6.png',
            '/stickers/7.png',
            '/stickers/8.png',
            '/stickers/9.png',
            '/stickers/10.png',
            '/stickers/11.png',
            '/stickers/12.png',
            '/stickers/13.png',
        ]
        const stickerImages = stickers.map((sticker)=>{
            const stkr = new Image();
            stkr.crossOrigin = 'anonymous';
            stkr.src = sticker;
            return stkr;
        })
        this.setState({
            stickers: stickerImages,
        })
    }

    
    render(){
        const { classes, frameCount } = this.props;
        const { stickers, stickerBarActive } = this.state;
        return(
            <React.Fragment>
            { 
                this.props.canvasRef &&
                <div className={classes.root}>
                    <span className={classes.HeaderTyp}>დაამატე</span>
                    <span className={classes.hr}/>
                    <Button style="secondary" onClick={()=>this.props.canvasRef.handlers.addText('ჩაასწორე',Math.random()*100, frameCount)} className={classes.btnAction}><IconFont className={classes.btnIcon} color="#d8d8d8"/> ტექსტი</Button>
                    <Button style="secondary" onClick={this.toggleStickers} className={classes.btnAction}>სტიკერი</Button>
                    {
                        stickerBarActive &&
                        <div className={classes.stickerContainer}>
                            {
                                stickers.map((sticker:any,i:number)=>
                                    <div key={i} onClick={()=>this.props.canvasRef.handlers.addSticker(sticker.src, Math.random()*100, frameCount)}>
                                        <img src={sticker.src}/>
                                    </div>
                                )
                            }
                        </div>
                    }                    
                </div>
            }
            </React.Fragment>            
        )
    }
}


export default widthStyles(styles)(AddObjects);