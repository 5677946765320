import React, { Component } from 'react';
import withStyles from 'react-jss';
import Layout from './layout/Layout';
import Dropzone from 'react-dropzone';
import UploadIcon from '../../assets/icons/upload.svg';
import PlusIcon from '../../assets/icons/plus.svg';
import Button from '../../components/Button';
import {Redirect} from 'react-router-dom';
import { STUDIO_API_URL } from '../../config';
import studioAPI from '../../helpers/studioAPI';

const styles = {
    root:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    studioLogo: {
        position: 'absolute',
        top: 20,
        height: 20,
    },
    uploadContainer: {
        width: '65vmin',
        height: '65vmin',
        backgroundColor: '#292929',
        borderRadius: 32,
        boxShadow: '0 15px 30px rgba(0,0,0,30%)',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 20,
        "& > *":{
            animation: 'fade 0.5s ease-in-out forwards'
        }
    },
    uploadHeader:{
        display: "flex",
        flexDirection: "center",
        justifyContent: "center",
        "& img":{
            width: 20,
            height: 20,
        },
        "& span":{
            fontFamily: 'HelveticaCaps',
            fontWeight: 'bold',
            color: '#D8D8D8',
            paddingLeft: 10,
        }
    },
    dndContainer:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: 'center',
        outline: 'none',
        backgroundColor: '#121212',
        padding: 20,
        width: '80%',
        height: 250,
        borderRadius: 12,
        cursor: 'pointer',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        "& span":{
            fontFamily: 'HelveticaCaps',
            fontWeight: 'bold',
            color: '#D8D8D8',
            marginTop: 15,
        },
    },
    inputWrapper:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: '#121212',
        padding: 20,
        borderRadius: 16,
    },
    "@keyframes fade":{
        "from":{
            opacity: 0,
        },
        "to":{
            opacity: 1,
        }
    },
}

interface IProps{
    classes: any;
}

class Upload extends Component<IProps>{
    public state: any;
    constructor(props:IProps){
        super(props)
        this.state = {
            uploadedGif: undefined,
            handleNext: false,
        }
        this.handleFileChoose = this.handleFileChoose.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    async handleFileChoose(files:any){
        if(files){
            let formData = new FormData();
            formData.append('file', files[0]);
            const uploadGifCall = await studioAPI.post(`gifs`,
                formData
                ,{ headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'multipart/form-data' } });
            const ytGifResp = await uploadGifCall.data;
            const ytGifFile = ytGifResp.fileName;
            const gifUrl = `${STUDIO_API_URL}${ytGifFile}`;
            console.log(gifUrl);
           this.setState({
                uploadedGif: gifUrl,
           })
        }
    }

    handleNext(){
        this.setState({
            handleNext: true,
        })
    }

    render(){
        const {classes} = this.props;
        const { uploadedGif, handleNext } = this.state;
        return(
            <Layout withLogo>
                {
                    handleNext&&<Redirect to={{
                        pathname: '/home/loader',
                        state: { gifUrl: uploadedGif }
                    }} push />
                }
                <div className={classes.root}>
                    <div className={classes.uploadContainer}>
                        <div className={classes.uploadHeader}>
                            <img src={UploadIcon}/>
                            <span>ატვირთე</span>
                        </div>
                        <Dropzone accept="image/gif" onDrop={acceptedFiles => this.handleFileChoose(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                                <div style={uploadedGif && {backgroundImage: `url(${uploadedGif})`}} className={classes.dndContainer} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className={classes.inputWrapper}>
                                        <img src={PlusIcon}/>
                                        <span>აირჩიე ან ჩააგდე</span>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                        <Button onClick={this.handleNext} disabled={uploadedGif?false:true}>გაგრძელება</Button>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default withStyles(styles)(Upload)