import React from 'react';
import withStyles from 'react-jss';

const styles = {
    pill: {
        border: '1px solid #fff',
        borderRadius: '2rem',
        color: '#fff',
        minWidth: 78,
        fontSize: 15,
        padding: '3px 8px',
        textAlign: 'center',
        cursor: 'pointer',
    },
    pillActive: {
        color: '#545454',
        backgroundColor: '#fff',
    }
}

interface IProps {
    classes?: any;
    active: boolean;
    children: any;
    className?: string;
    onClick?: any;
}

const Pill = (props: IProps) => {
    const { classes, children, active, onClick } = props;
    return (
        <div onClick={onClick} className={[classes.pill, (active ? classes.pillActive : ""), props.className].join(' ')}>
            {children}
        </div>
    )
}

export default withStyles(styles)(Pill);