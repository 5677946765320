import ActionTypes from '../constants';


export default (state = {}, action:{type: string, payload: any}) => {
    switch(action.type){
        case ActionTypes.getProfile:
        return {
            ...state,
            username: action.payload.nickName,
            profilePic: action.payload.picture,
            id: action.payload.id,
        }
        case ActionTypes.signIn:
        return {
            ...state,
            accessToken: action.payload.accessToken
        }
        default:
            return {
                ...state
            }
    }
}