import React from 'react';
import withStyles from 'react-jss';


const styles = {
    root:{
        padding: '15px 25px',
        borderRadius: 16,
        fontSize: 18,
        fontFamily: 'HelveticaCaps',
        fontWeight: 'bold',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
    },
    btnPrimary:{
        backgroundColor: '#FC3D4A',
        color: '#D8D8D8',
    },
    btnSecondary:{
        backgroundColor: '#6B6B6B',
        color: '#D8D8D8',
    },
    btnSuccess:{
        backgroundColor: '#00E489',
        color: '#fff',
    },
    btnDanger:{
        backgroundColor: '#FC3D4A',
        color: '#fff',
    },
    btnFB:{
        backgroundColor: '#4267b2',
        color: '#fff',
    },
}

const Button = (props:any) => {
    const {classes, onClick} = props;
    let btnStyle = classes.btnPrimary;
    switch(props.style){
        case 'primary':
            btnStyle = classes.btnPrimary;
            break;
        case 'secondary':
            btnStyle = classes.btnSecondary;
            break;
        case 'success':
            btnStyle = classes.btnSuccess;
            break;
        case 'danger':
            btnStyle = classes.btnDanger;
            break;
        case 'fb':
            btnStyle = classes.btnFB;
            break;
        default:
            break;
    }
    const btnClassName = [props.className, classes.root, btnStyle].join(' ');
    return(
        <button className={btnClassName} onClick={onClick}>{props.children}</button>
    )
}

export default withStyles(styles)(Button)