import React, { Component } from 'react';
import withStyles from 'react-jss';
import Layout from './layout/Layout';
import LinkIcon from '../../assets/icons/link.svg';
import {Redirect} from 'react-router-dom';
import { STUDIO_API_URL } from '../../config';
import studioAPI from '../../helpers/studioAPI';

const styles = {
    root:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    studioLogo: {
        position: 'absolute',
        top: 20,
        height: 20,
    },
    linkContainer: {
        width: '65vmin',
        height: 250,
        position: 'absolute',
        transition: 'all 0.3s',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        margin: 0,
        backgroundColor: '#292929',
        borderRadius: 32,
        boxShadow: '0 15px 30px rgba(0,0,0,30%)',
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 20,
        "& > *":{
            animation: 'fade 0.5s ease-in-out forwards'
        }
    },
    linkHeader:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        "& img":{
            width: 20,
            height: 20,
        },
        "& span":{
            fontFamily: 'HelveticaCaps',
            fontWeight: 'bold',
            color: '#D8D8D8',
            paddingLeft: 10,
        }
    },
    linkInputContainer:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        "& > div":{
            width: '100%',
            position: 'relative',
            "& > input":{
                width: 'calc(100% - 6px)',
                fontFamily: 'HelveticaCaps',
                fontSize: 16,
                fontWeight: 'bold',
                height: 34,
                background: 'none',
                appearance: 'none',
                WebkitAppearance: 'none',
                MozAppearance: 'none',
                outline: 'none',
                border: 'none',
                borderBottom: '1px solid #707070',
                paddingRight: 70,
                color: '#d7d7d7',
                transition: 'border-bottom 0.2s',
            },
            "& > button": {
                position: 'absolute',
                right: 0,
                bottom: 0,
                height: 34,
                fontWeight: 'bold',
                fontFamily: 'HelveticaCaps',
                fontSize: 16,
                color: '#D8D8D8',
                backgroundColor: '#707070',
                border: 'none',
                width: 70,
                borderRadius: '6px 6px 6px 0px',
                cursor: 'pointer',
                transition: 'all 0.2s',
                outline: 'none',
            }
        }
    },
    linkInputWarn:{
        "& > div > input":{
            borderBottom: '1px solid #923339',
        },
        "& > div > button":{
            backgroundColor: '#923339',
            color: '#fff',
        }
    },
    validInput:{
        "& > div > input":{
            color: '#53d085',
        },
    },
    "@keyframes fade":{
        "from":{
            opacity: 0,
        },
        "to":{
            opacity: 1,
        }
    },
}

interface IProps{
    classes: any;
}

class Link extends Component<IProps>{
    public state: any;
    constructor(props:IProps){
        super(props)
        this.state = {
            handleNext: false,
            inputVal: '',
            isValidInput: true,
            shouldWarn: false,
            gifUrl: '',
        }
        this.handleInput = this.handleInput.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    handleInput(e:any){
        const inputVal = e.target.value;
        this.setState({
            inputVal,
        })
        const isValid = /^(?:([^:/?#]+):)?(?:([^/?#]*))?([^?#]*\.(?:gif))(?:\?([^#]*))?(?:#(.*))?$/g.test(inputVal);
        this.setState({
            isValidInput: isValid,
        });
        if(inputVal && !isValid){
            this.setState({
                shouldWarn: true,
            })
        } else {
            this.setState({
                shouldWarn: false,
            })
        }
    }

    async handleNext(){
        const {isValidInput, inputVal} = this.state;
        if(isValidInput && inputVal){
            const uploadGifCall = await studioAPI.post(`gifs/${encodeURIComponent(inputVal)}`,null
            ,{ headers: { "Access-Control-Allow-Origin": "*", } })
            const ytGifResp = await uploadGifCall.data;
            const ytGifFile = ytGifResp.fileName;
            const gifUrl = `${STUDIO_API_URL}${ytGifFile}`;
            this.setState({
                handleNext: true,
                gifUrl,
            })
        }
    }

    render(){
        const {classes} = this.props;
        const { handleNext, shouldWarn, isValidInput, inputVal, gifUrl } = this.state;
        return(
            <Layout withLogo>
                {
                    handleNext&&<Redirect to={{
                        pathname: '/home/loader',
                        state: { gifUrl, }
                    }} push />
                }
                <div className={classes.root}>
                    <div className={classes.linkContainer}>
                        <div className={classes.linkHeader}>
                            <img src={LinkIcon}/>
                            <span>გააგდე URL</span>
                        </div>
                        <div
                            className={!shouldWarn
                                        ? ( isValidInput && inputVal ? [classes.linkInputContainer, classes.validInput].join(' ') :classes.linkInputContainer)
                                        :[classes.linkInputContainer, classes.linkInputWarn].join(' ')}>
                            <div>
                                <input spellCheck={false} onSubmit={this.handleNext} onChange={this.handleInput} onInput={this.handleInput} type="text" placeholder="ჩაწერე GIF - ის ბმული"/>
                                <button onClick={this.handleNext}>GO</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default withStyles(styles)(Link)