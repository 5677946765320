import React, { Component } from 'react'

export default class IconTimes extends Component<any> {
    render() {
        return (
            <div className={this.props.className} onClick={this.props.onClick}>
                <span style={{ color: (this.props.color || '#000') }} className="icon-times"></span>
            </div>
        )
    }
}