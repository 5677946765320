import React, { Component } from 'react';
import withStyles from 'react-jss';
import TimesIcon from '../icons/IconTimes';
import _ from 'lodash';

const styles = {
    textLayer: {
        fontFamily: 'HelveticaCaps',
        fontSize: 16,
        padding: '13px 35px',
        color: '#fff',
        backgroundColor: '#2d2d2d',
        position: 'relative',
        marginBottom: 1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: 46,
    },
    textLayerRemove: {
        position: 'absolute',
        right: 15,
        top: 15,
        cursor: 'pointer',
    }
}

interface IProps {
    canvasObjects: any;
    canvasRef: any;
    classes?: any;
}

class Layer extends Component<IProps>{

    removeObject(canvasObject: any) {
        this.props.canvasRef.handlers.removeObject(canvasObject);
    }

    setActiveObject(canvasObject:any){
        this.props.canvasRef.handlers.setActiveObject(canvasObject);
    }

    render() {
        const { classes } = this.props;
        const objectList = _.clone(this.props.canvasObjects);

        const textBorder = (color: any) => {
            if (color)
                return {
                    textShadow: `1px 0 0 ${color}, -1px 0 0 ${color}, 0 1px 0 ${color}, 0 -1px 0 ${color}`
                }
            return {
                textShadow: 'none'
            }
        }
        const textStyle = (style: any): object => {
            switch (style) {
                case 'bold':
                    return {
                        fontWeight: 'bold',
                    }
                case 'italic':
                    return {
                        fontStyle: 'italic',
                    }
                default:
                    break;
            }
            return {
                fontWeight: 'normal'
            }
        }
        return (
            objectList.reverse().map((canvObj: any) => {
                return (
                    <div className={classes.textLayer} key={canvObj.id}>
                        {
                        canvObj.type === 'text'
                        ?
                        <span onClick={()=>this.setActiveObject(canvObj.obj)} style={{ color: canvObj.color, fontFamily: canvObj.fontFamily, cursor: 'pointer', ...textBorder(canvObj.borderColor), ...textStyle(canvObj.fontStyle) }}>
                            {canvObj.text}
                        </span>
                        :
                        <span onClick={()=>this.setActiveObject(canvObj.obj)}>
                            <img style={{width: 'auto', height: 32, marginTop: -6, objectFit: 'contain'}} src={canvObj.stickerSrc}/>
                        </span>
                        }
                        <TimesIcon onClick={() => { this.removeObject(canvObj.obj) }} className={classes.textLayerRemove} color="#B2B2B2" />
                    </div>
                )
            })
        )
    }
}
export default withStyles(styles)(Layer)