import React, { Component } from 'react';
import withStyles from 'react-jss';
import Layout from './layout/Layout';
import Button from '../../components/Button';
import {Redirect, Link} from 'react-router-dom';
import axios from 'axios';
import Cookies from '../../helpers/cookies';
import { connect } from 'react-redux';
import { getProfile } from '../../redux/actions/auth';

const styles = {
    root:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    studioLogo: {
        position: 'absolute',
        top: 20,
        height: 20,
    },
    gifContainer: {
        width: '75vmin',
        height: '75vmin',
        position: 'absolute',
        transition: 'all 0.3s',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        margin: 0,
        backgroundColor: '#292929',
        borderRadius: 32,
        boxShadow: '0 15px 30px rgba(0,0,0,30%)',
        flexDirection: "column",
        justifyContent: "space-between",
        display: 'flex',
        padding: 35,
        "& img":{
            width: '100%',
            maxHeight: '50%',
            objectFit: 'contain',
            borderRadius: 19,
            backgroundColor: '#121212',
        },
        "& > *":{
            animation: 'fade 0.5s ease-in-out forwards'
        }
    },
    buttonContainer:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 -10px',
        "& > *":{
            width: 'calc(100% / 3 - 20px)',
            margin: 10,
        },
        "& > a button":{
            width: '100%',
        }
    },
    inputContainer:{
        "& input":{
            width: 'calc(100% - 6px)',
            fontFamily: 'HelveticaCaps',
            fontSize: 16,
            fontWeight: 'bold',
            height: 34,
            background: 'none',
            appearance: 'none',
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            outline: 'none',
            border: 'none',
            borderBottom: '1px solid #707070',
            paddingRight: 70,
            color: '#d7d7d7',
            transition: 'border-bottom 0.2s',
            marginTop: 30,
        },
    },
    "@keyframes fade":{
        "from":{
            opacity: 0,
        },
        "to":{
            opacity: 1,
        }
    },
}

interface IProps{
    classes: any;
    location?: any;
    getProfile?: any;
    auth?: any;
}

class ReadyGif extends Component<IProps>{
    public state: any;
    constructor(props:IProps){
        super(props)
        this.state = {
            handleNext: false,
            titleInput: '',
            tagInput: '',
            isAuthed: false,
        }
        this.handleTitleInput = this.handleTitleInput.bind(this);
        this.handleTagsInput = this.handleTagsInput.bind(this);
        this.uploadGif = this.uploadGif.bind(this);
    }

    componentWillMount(){
        const accessToken = Cookies.get('giffy.accessToken')
        if(accessToken){
            this.props.getProfile(accessToken);
            this.setState({
                isAuthed: true,
            })
        }
    }

    handleTitleInput(e:any){
        this.setState({
            titleInput:e.target.value,
        })
    }

    handleTagsInput(e:any){
        this.setState({
            tagInput:e.target.value,
        })
    }

    async uploadGif(){
        const { titleInput, tagInput } = this.state;
        var bodyFormData = new FormData();
        bodyFormData.set('name',titleInput);
        bodyFormData.set('hashTags', tagInput);
        bodyFormData.set('categoryId', '4');
        bodyFormData.set('published', 'true');
        bodyFormData.set('userId', this.props.auth.id+'');
        bodyFormData.set('isFeature', 'false');
        bodyFormData.set('showInFeed', 'true');
        bodyFormData.set('watermarkPositionId', '');
        bodyFormData.set('fileUrl', this.props.location.state.gifUrl+'');
        bodyFormData.set('channelId', '1');
        bodyFormData.set('source', '');
        const request = await axios.post('https://api.gifebi.ge/api/v1/Image/Upload', bodyFormData, {
            headers: {
                'authorization': `Bearer ${Cookies.get('giffy.accessToken')}`,
                'apiKey': `2DAE48B0-47F5-4E6F-8151-29B8278BA40F`,
                'content-type': 'multipart/form-data'
            },
        })
        const gifebiUrl = await request.data;
        if(gifebiUrl.imageHashid){
            window.location.href = `https://gifebi.ge/gif-${gifebiUrl.imageHashid}`;
        }
    }

    render(){
        const {classes} = this.props;
        const {titleInput, tagInput, isAuthed} = this.state;
        return(
            <Layout withLogo>
            {
                !(this.props.location.state && this.props.location.state.gifUrl)
                ?
                <Redirect to={{
                    pathname: '/',
                }} />
                :
                <div className={classes.root}>
                    <div className={classes.gifContainer}>
                        <img src={this.props.location.state.gifUrl}/>
                        {
                            isAuthed && 
                            <div className={classes.inputContainer}>
                                <input onInput={this.handleTitleInput} value={titleInput} type="text" placeholder="სათაური"/>
                                <input onInput={this.handleTagsInput} value={tagInput} type="text" placeholder="ტეგები"/>
                            </div>
                        }
                        <div className={classes.buttonContainer}>
                            <a href={this.props.location.state.gifUrl}><Button style="secondary">გადმოწერე</Button></a>
                            {
                                isAuthed && 
                                <Button onClick={this.uploadGif}>ატვირთვე</Button>
                            }                            
                            <Link to="/"><Button style="secondary">წაშალე</Button></Link>
                        </div>
                    </div>
                </div>
            }
            </Layout>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.authReducer,
    }
}

export default connect(mapStateToProps,{getProfile})(withStyles(styles)(ReadyGif));