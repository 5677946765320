export default {
    setFrame: 'SET_FRAME',
    setDelayDelta: 'SET_DELAY_DELTA',
    setFrameCount: 'SET_FRAME_COUNT',
    setFrameDelay: 'SET_FRAME_DELAY',
    setPlaying: 'SET_PLAYING',
    setDimensions: 'SET_DIMENSIONS',
    setFrames: 'SET_FRAMES',
    signIn: 'SIGN_IN',
    getProfile: 'GET_PROFILE',
}