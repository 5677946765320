import React, { Component } from 'react';
import withStyles from 'react-jss';
import StudioLogo from '../../../assets/images/studio-logo.svg'
import { Link } from 'react-router-dom';

const styles = {
    root:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#121212',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        padding: 30,
        position: 'relative',
    },
    studioLogo: {
        position: 'absolute',
        top: 20,
        height: 20,
        left: 0,
        right: 0,
        margin: 'auto',
    },
    copyright:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        "& span":{
            color: '#808080',
            fontFamily: 'Helvetica',
        }
    },
}

interface IProps{
    classes: any;
    withLogo?: boolean;
}

class Layout extends Component<IProps>{
    public state: any;
    constructor(props:IProps){
        super(props)
        this.setActive = this.setActive.bind(this);
        this.state={
            uploadActive: false,
            linkActive: false,
            youtubeActive: false,
        }
    }
    setActive(el:string){
        this.setState({
            uploadActive: !this.state.uploadActive,
        })
    }
    render(){
        const { classes,withLogo } = this.props;
        const { uploadActive } = this.state;
        return(
            <div className={classes.root}>
                {
                    withLogo && <Link to={'/'}><img className={classes.studioLogo} src={StudioLogo}/></Link>
                }
                {
                    this.props.children
                }
                <div className={classes.copyright}>
                    <span>2019 Lemondo LLC</span>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(Layout)